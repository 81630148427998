import React, { useState, useRef, useEffect, useContext} from 'react';
import { UserContext } from '../context/UserContext'
import { Link } from 'react-router-dom';
import Social from './Social'
import './Header.css'
import ReactGA from 'react-ga'

const toggleLang= (_browserLang,_setBrowserLang) => {
  
  _browserLang==='es'?_setBrowserLang('en'):_setBrowserLang('es')
} 

function Header({back,
                 ...props}) {

  const evtSocialVisit = _label => {
    ReactGA.event({
      category: 'SOCIAL',
      action: 'VISIT',
      label: _label
    })
  }
                
  const { 
    browserLang,
    setBrowserLang,
    canIMintWhiteList
  } = useContext(UserContext);
    
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const evtPageVisit = _label => {
    
    ReactGA.event({
      category: 'BROWSE',
      action: 'VISIT',
      label: _label
    })

    console.log("after GA event "+_label)
  }
  
  const backButtonLi = (
    <li>
      <Link to="/" className="font-medium w-full inline-flex items-center justify-center border mr-3 border-transparent px-4 py-2 my-2 rounded-sm text-white bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out">Back</Link>
    </li>
  );

  let wlLink = (
    <li>
        <Link to="/wlmint" onClick = {() => evtPageVisit('WLMINT')}
          className="flex text-red-300 hover:text-gray-200 py-2">WL Mint</Link>
    </li>
  )
  
  let backButton = (
    <ul className="flex flex-grow justify-end flex-wrap items-center">
      {backButtonLi}      
    </ul>
  );

  if(back!='2true') 
    backButton=(<></>);

  return (
    <header className="header w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">

          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" onClick = {() => evtPageVisit('HOME')} className="block" aria-label="Cruip">
              <img className="mx-auto rounded-full" src={require('../images/rps_logo.jpg').default} width="100" height="100" alt="Hero" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">

            {/* Desktop menu links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">              
              <li>
                <Link to="#0" onClick={() => { toggleLang(browserLang, setBrowserLang) }} >{browserLang==='es'?'🇺🇸':'🇪🇸'}</Link>
              </li>
              <li>
                <Link to="/features" onClick = {() => evtPageVisit('FEATURES')}
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                  {browserLang==='es'?"Singularidades":"Features"}
                </Link>
              </li>
              <li>
                <Link to="/scissors" onClick = {() => evtPageVisit('SCISSORS')}
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                  {browserLang==='es'?"Drop Tijeras":"Scissors Drop"}
                </Link>
              </li>
              <li>
                <Link to="/mint" onClick = {() => evtPageVisit('MINT')}
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                  Mint
                </Link>
              </li>
              
              { canIMintWhiteList && wlLink }

              <li>
                <Link to="/team" onClick = {() => evtPageVisit('TEAM')} 
                      className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                  {browserLang==='es'?"Equipo":"About us"}
                </Link>
              </li>
              <li>
                <Link to="/roadmap" onClick = {() => evtPageVisit('ROADMAP')}
                      className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                  Roadmap
                </Link>
              </li>
              <li>
                <Link to="/help" onClick = {() => evtPageVisit('HELP')}
                  className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out">
                    {browserLang==='es'?"Ayuda":"Help"}
                </Link>
              </li>              
            </ul>

            { backButton }
  
          </nav>
          
          <div className="md:flex md:items-center md:justify-between">
            <div className="mb-3">
                <ul className="flex flex-wrap text-xs font-medium -m-1">
                  <li className="m-1">
                    <a className="inline-flex text-center text-gray-100 ml-5 py-1 px-3 rounded-full items-center justify-center bg-purple-600 hover:bg-white transition duration-150 ease-in-out"
                        href="https://discord.gg/7gYMQ2spVa"  
                        target="_blank" rel="noreferrer" 
                        onClick = {() => evtSocialVisit('DISCORD')}>{browserLang==='es'?"ÚNETE AL DISCORD":"JOIN OUR DISCORD"}</a>
                  </li>                      
                </ul>
            </div>           
          </div>

          {/* Mobile menu */}
          <div className="md:hidden">

            {/* Hamburger button */}
            <button ref={trigger} className={`hamburger ${mobileNavOpen && 'active'}`} aria-controls="mobile-nav" aria-expanded={mobileNavOpen} onClick={() => setMobileNavOpen(!mobileNavOpen)}>
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav id="mobile-nav" ref={mobileNav} className="absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out" style={mobileNavOpen ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: .8 } }>
              <ul className="bg-gray-800 px-4 py-2">
                <li>
                  <Link to="#0" onClick={() => { toggleLang(browserLang, setBrowserLang) }} >{browserLang==='es'?'🇺🇸 English':'🇪🇸 Español'}</Link>
                </li>
                <li>
                  <Link to="/features" 
                    onClick = {() => evtPageVisit('FEATURES')}
                    className="flex text-gray-300 hover:text-gray-200 py-2">{browserLang==='es'?"Singularidades":"Features"}</Link>
                </li>
                <li>
                  <Link to="/scissors" onClick = {() => evtPageVisit('SCISSORS')}
                    className="flex text-gray-300 hover:text-gray-200 py-2">{browserLang==='es'?"Drop Tijeras":"Scissors Drop"}</Link>
                </li>
                <li>
                  <Link to="/mint" onClick = {() => evtPageVisit('MINT')}
                   className="flex text-gray-300 hover:text-gray-200 py-2">Mint</Link>
                </li>

                { canIMintWhiteList && wlLink }

                <li>
                  <Link to="/team" onClick = {() => evtPageVisit('TEAM')}
                    className="flex text-gray-300 hover:text-gray-200 py-2">{browserLang==='es'?"Equipo":"About us"}</Link>
                </li>
                <li>
                  <Link to="/roadmap" onClick = {() => evtPageVisit('ROADMAP')}
                    className="flex text-gray-300 hover:text-gray-200 py-2">Roadmap</Link>
                </li>                
                <li>
                  <Link to="/help" onClick = {() => evtPageVisit('HELP')}
                  className="flex text-gray-300 hover:text-gray-200 py-2">{browserLang==='es'?"Ayuda":"Help"}</Link>
                </li>
                { backButtonLi }
              </ul>
            </nav>

          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;
