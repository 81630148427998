import { React,useContext } from 'react';
import { useFilteredRoadmaps } from '../utils/FilteredRoadmap'
import { UserContext } from '../context/UserContext'

import './Timeline.css'
import {roadmapItems} from '../utils/RoadmapGroups'


function Timeline() {

  const { browserLang } = useContext(UserContext);

  const filteredRoadmaps = useFilteredRoadmaps(browserLang);

  return (
    <section>      
      <div className="max-w-6xl mx-auto px-4 sm:px-6 ">        
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Items */}
          { roadmapItems('Roadmap',filteredRoadmaps[1]) }

        </div>
      </div>
    </section>
  );
}

export default Timeline;