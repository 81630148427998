import { roadmaps as roadmapsEn } from '../data/en/roadmap'
import { roadmaps as roadmapsEs } from '../data/es/roadmap'

export const filter_roadmaps = (data,group) => { 
  
    return (
      data.filter((data,key) => {     
        return(data.group.localeCompare(group)?false:true);
      })  
    )
  }

  export function useFilteredRoadmaps(lang) {
  
    let roadmaps = roadmapsEn;
  
    switch(lang) {
      case "es":
        roadmaps = roadmapsEs;  
        break;    
    }

    const filteredRoadmaps =  [
      filter_roadmaps(roadmaps,'summary'), // Roadmaps - Summary
      filter_roadmaps(roadmaps,'dates q1'), // Roadmaps - Q1 dates    
      filter_roadmaps(roadmaps,'roadmap papers summary'), // Roadmap Papers Summary
      filter_roadmaps(roadmaps,'dates papers'), // Roadmaps - Q2 dates
      filter_roadmaps(roadmaps,'roadmap rocks summary'), // Roadmap Rocks Summary
      filter_roadmaps(roadmaps,'dates rocks') // Roadmaps - Q3 dates
    ];
      
    return filteredRoadmaps;
  }