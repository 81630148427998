import React from 'react';
import ReactGA from 'react-ga'

function FollowUs() {
  const evtSocialVisit = _label => {
    ReactGA.event({
      category: 'SOCIAL',
      action: 'VISIT',
      label: _label
    })
  }

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2" data-aos="fade-up">Follow us!</h2>
          </div>

          {/* Social */}
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">

              {/* Twitter */}
              <article className="flex flex-col h-full" data-aos="fade-up">
                <header>
                  <a className="block mb-6" href="https://twitter.com/RpsNFT_Art"  
                      target="_blank" rel="noreferrer" 
                      onClick = {() => evtSocialVisit('TWITTER')}>
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute scale-90 inset-0 w-fit h-fit object-cover transform hover:scale-110 transition duration-700 ease-out" 
                           src={require('../images/Twitter-logo.svg').default} 
                           width="352" height="198" alt="Twitter" />
                    </figure>
                  </a>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-purple-600 hover:bg-white transition duration-150 ease-in-out"
                           href="https://twitter.com/RpsNFT_Art"  
                           target="_blank" rel="noreferrer" 
                           onClick = {() => evtSocialVisit('TWITTER')}>Twitter</a>
                      </li>                      
                    </ul>
                  </div>                  
                </header>                                
              </article>

              {/* Instagram */}
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="200">
                <header>
                  <a className="block mb-6" href="https://www.instagram.com/rpsnft_art"                       
                      target="_blank" rel="noreferrer" 
                      onClick = {() => evtSocialVisit('INSTAGRAM')}>
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-fit scale-90 object-cover transform hover:scale-110 transition duration-700 ease-out" 
                           src={require('../images/Instagram_logo.svg').default} 
                           width="352" height="198" alt="Instagram" />
                    </figure>
                  </a>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-pink-500 hover:bg-white transition duration-150 ease-in-out" 
                          href="https://www.instagram.com/rpsnft_art"                           
                          target="_blank" rel="noreferrer" 
                          onClick = {() => evtSocialVisit('INSTAGRAM')}>Instagram</a>
                      </li>                      
                    </ul>
                  </div>
                </header>
              </article>

              {/* Medium */}
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="400">
                <header>
                  <a className="block mb-6" 
                    href="https://medium.com/@rpsnft_art"
                    target="_blank" rel="noreferrer" 
                    onClick = {() => evtSocialVisit('MEDIUM')}>
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-y-10 w-full h-fit scale-90 object-cover bg-white transform hover:scale-150 transition duration-700 ease-out" 
                           src={require('../images/Medium_logo.svg').default} 
                           width="352" height="198" alt="Medium" />
                    </figure>
                  </a>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-purple-600 hover:bg-white transition duration-150 ease-in-out" 
                                              href="https://medium.com/@rpsnft_art"
                                              target="_blank" rel="noreferrer" 
                                              onClick = {() => evtSocialVisit('MEDIUM')}>Medium</a>
                      </li>                      
                    </ul>
                  </div>
                </header>
              </article>

            </div>
          </div>
          
          {/* Discord and Github*/}
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">

              {/* Discord */}
              <article className="flex flex-col h-full" data-aos="fade-up">
                <header>
                  <a className="block mb-6" href="https://discord.gg/7gYMQ2spVa"  
                      target="_blank" rel="noreferrer" 
                      onClick = {() => evtSocialVisit('DISCORD')}>
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-y-10 scale-90 w-full h-fit object-cover transform hover:scale-110 transition duration-700 ease-out" 
                           src={require('../images/Discord_logo.svg').default} 
                           width="352" height="198" alt="Discord" />
                    </figure>
                  </a>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-yellow-600 hover:bg-white transition duration-150 ease-in-out"
                           href="https://discord.gg/7gYMQ2spVa"  
                           target="_blank" rel="noreferrer" 
                           onClick = {() => evtSocialVisit('DISCORD')}>Discord</a>
                      </li>                      
                    </ul>
                  </div>                  
                </header>                                
              </article>

              {/* Github */}
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="200">
                <header>
                  <a className="block mb-6" href="https://github.com/RSPNFTArt"
                      target="_blank" rel="noreferrer" 
                      onClick = {() => evtSocialVisit('GITHUB')}>
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-fit scale-90 object-cover transform hover:scale-110 transition duration-700 ease-out" 
                           src={require('../images/Octocat.png').default}
                           width="352" height="198" alt="Github" />
                    </figure>
                  </a>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-pink-500 hover:bg-white transition duration-150 ease-in-out" 
                         href="https://github.com/RSPNFTArt"
                         target="_blank" rel="noreferrer" 
                         onClick = {() => evtSocialVisit('GITHUB')}>Github</a>
                      </li>                      
                    </ul>
                  </div>
                </header>
              </article>
            </div>
          </div>         
        </div>
      </div>
    </section>
  );
}

export default FollowUs;
