import { useState, useContext,useEffect } from "react"
import MintingForm from "../components/elements/MintingForm"
import Banner from './../components/elements/Banner'
import classNames from 'classnames';
import "./Minting.css"
import { UserContext } from "../context/UserContext";
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import PageIllustration from '../partials/PageIllustration';
import { Helmet } from "react-helmet";

const Minting = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {


    const { 
        browserLang,
        } = useContext(UserContext);

    const getPresalesStr = () => {        
        let presalestxt="During pre-sales you can mint up to 2 scissors per wallet.";
        if(inPreSales)
            presalestxt = presalestxt+"Start minting now!";
        else
            presalestxt = presalestxt+"Wait for the presales to start!";

        if(browserLang==='es') {
            presalestxt="Durante la pre-venta puedes hacer mint de hasta 2 tijeras por billetera."            
            
            if(inPreSales)
                presalestxt = presalestxt+"Empieza el minting ahora!";
            else
                presalestxt = presalestxt+"Espera a que comience la pre-venta!"
        }

        return presalestxt;        
    }
    
    const getSalesStr = () => {        
        let salestxt="During public sales you can mint up to 25 scissors per transaction. Start minting now!"        
        
        if(browserLang==='es') {            
            salestxt="Durante la venta pública puedes hacer mint de hasta 25 tijeras por transacción. Empieza el minting ahora!"            
        }

        return salestxt;
    }
        
    const PRESALES_PRICE = 0.055
    const PUBSALES_PRICE = 0.07
    const TOP_PER_TX = 25

    const [scissorsValue, setScissorsValue] = useState("")

    const {accounts, scissors, maxDrop, lastId, setLastId,
        inPreSales,presalesFinished,publicsalesFinished} = useContext(UserContext)

    /* Callback function that gets the current supply */
    const refreshSupply = async () => {
     
        if(!scissors) {
            return;          
        }
    
        if (isNaN(accounts)) {
            return
        }
    
        const _lastId = await scissors.methods.totalSupply().call()
        let _scissorsValue="No minting yet";
        if(_lastId != 0)
            _scissorsValue = await scissors.methods.tokenURI(_lastId).call()

        setLastId(_lastId);
        setScissorsValue(_scissorsValue);
    }
    
    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    
    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
      );


    const refreshLastId = async () => {
        await refreshSupply()
    }
     
    const noAccBanner = (    
        <Banner variant="danger" heading="Account not connected" connect="true">
            <p><strong>
                
            {browserLang==='es'?"Conecta con Metamask y refresca la página para poder hacer Mint.":"Connect with Metamask and refresh the page to be able to Mint."}
                
                </strong>
            </p>            
        </Banner>             
    )
        
    const isAccountsUnlocked = accounts ? accounts.length > 0 : false 
    
    const connectAccountBanner = (
        <>
        {
            !isAccountsUnlocked?
                noAccBanner
                : null
        }
        </>
    )

    const noContractBanner = (
        <Banner variant="danger" heading="Contract not found" parent={this}>
            <p>
              {browserLang==='es'?"No se pudo encontrar un contrato inteligente Scissors. Comprueba la consola para más detalles":"Could not find deployed Scissors contract. Check console for details."}
            </p>            
        </Banner>            
    )

    const ContractBanner = (
        <>
        {
            (!scissors)?
                noContractBanner
                : null
        }
        </>
    )

    const lastIdElem = (
        <>
        <div className="info-minting-text" id='lastId'>{browserLang==='es'?"Tijeras Minted":"Scissors Minted"}: {lastId} / {maxDrop}</div>
        <p></p>
        </>
    )

    const scissorsContractInfo = (
        <>
            {lastIdElem}
        </>
    )
    
    if(!scissors || !accounts) {
        return (
        
        <section className={outerClasses}>
            <div className={innerClasses}>
                <div>
                    {connectAccountBanner}
                </div>
                <div>
                    {ContractBanner}
                </div>
            </div>
        </section>)
    }

    // Request the refresh of the current supply
    refreshSupply()
    setInterval(refreshLastId, 1000);
                
    // Show in component
    return (
        <>
            <Helmet>
                <title>RPS NFT Art - Minting</title>  
                <meta name="description" content="Minting of Scissors NFT - Rock Scissors Paper - NFT for RPS Oracle Distributed Service."/>      
            </Helmet>
    
            <div className="flex flex-col min-h-screen overflow-hidden">
                {/*  Site header */}
                <Header back="true"/>
                
                {/*  Page content */}
                <main className="flex-grow">

                    {/*  Page illustration */}
                    <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
                    <PageIllustration />
                    </div>

                    <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                            {/* Page header */}
                            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                                <h1 className="h1 mb-4" data-aos="fade-up">{browserLang==='es'?"Mint de tus Tijeras":"Mint your Scissors"}</h1>
                                <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">{(!presalesFinished)?getPresalesStr():getSalesStr()}</p>
                                <p className="text-xl text-red-400" data-aos="fade-up" data-aos-delay="800">{browserLang==='es'?"Billetera conectada:":"Account connected:"} {(accounts)?accounts[0]:null}</p>            
                            </div>

                            <div>
                                {connectAccountBanner}
                            </div>

                            <MintingForm 
                                price={(!presalesFinished)?PRESALES_PRICE:PUBSALES_PRICE} 
                                top_per_tx={(!presalesFinished)?2:TOP_PER_TX}
                                refresh_supply_cb={refreshSupply} 
                            />

                            <br/>

                            {scissorsContractInfo}
                        </div>
                    </div>
                    </section>
                </main>
                
                {/*  Site footer */}
                <Footer />

            </div>
        </>
    );
}

export default Minting
