export const faqs = [
 {
    id: 1,
    question: "Cómo puedo obtener mi Tijera?",
    answer:["Las Tijeras :v:, estarán disponibles para su compra en nuestra web mediante una venta inicial (minting). \
    A la hora de la compra una tijera al azar se creará en la cadena de bloques [Ethereum](https://ethereum.org/es/) y \
    entregada en tu billetera immediatamente :purse:."],
    group: "wow"
 },
 {
    id: 2,
    question: "Pero, cómo funciona?",
    answer:["Para crear una nueva tijera necesitarás tener instalado la [extensión MetaMask](https://metamask.io/faqs/) en tu navegador \
    y enlazar tu crypto-billetera. Asegúrate de tener suficientes fondos ETH en tu billetera para cubrir los costes de la generación \
    de la tijera más los costes asociados de \
     [gas Ethereum](https://www.youtube.com/watch?v=WWn67-tY2PU&ab_channel=EducaBlock) :fuelpump:",
     "Durante la venta, indica una cantidad de tijeras, pulsa el botón “Mint” de nuestra web, aprueba la transacción en MetaMask y \
     eso será todo :wink: Si no sabes como configurar la billetera de Metamask :fox_face:, por favor revisa \
     [este video](https://www.youtube.com/watch?v=eOeOSWMRqUU&ab_channel=CryptoSheinix)."],
     group: "wow"
 },
 {
    id: 3,
    question: "Cuál es el precio de una Tijera?",
    answer: ["El precio de una tijera :label: durante la pre-venta será de **0,055 ETH + gas**. El precio durante la \
    **venta pública será de 0.07 ETH + gas**."],
    group: "price"
 },
 {
    id: 4,
    question: "Cual es el precio de una tijera VIP?",
    answer: ["El precio de cada tijera VIP :sunglasses: **es de 0,15 ETH + gas** :label:. Estas tijeras estarán disponibles \
    en [OpenSea.io](https://opensea.io/collection/rps-scissors)."],
    group: "price"
 },
 {
    id: 5,
    question: "Cual es la disponibilidad total?",
    answer: ["Como en todo lo que es valioso :amphora:, la escasez es obligatoria en la colección. Un total máximo de **10,000 NFTs de tijeras únicas** se creará \
    en la collección."],
    group: "wow"
 },
 {
    id: 6,
    question: "Cuando será la fecha de salida pública?",
    answer: ["La fecha para la venta pública de Tijeras es el :date: **25 de Marzo de 2022** :tada:. Estate alerta :mag: en el contador para la salida pública!"],
    group: "roadmap"
 },
 {
    id: 7,
    question: "Cuando es la fecha de pre-venta?",
    answer: ["La fecha de pre-venta es :date: el **18 de Marzo de 2022**. Estate alerta :eyes: en el contador para la pre-venta!"],
    group: "roadmap"
 },
 {
    id: 8,
    question: "Cuantas tijeras puedo comprar en una transacción durante pre-venta?",
    answer: ["Hay un límite de :two: **Tijeras** que se pueden comprar **por billetera** en :one: o :two: transacciones. \
    Durante la pre-venta no puedes comprar más de dos tijeras en total por billetera."],
    group: "roadmap"
 },
 {
    id: 9,
    question: "Cuantas tijeras puedo comprar en una transacción durante la venta pública?",
    answer: ["Hay un límite de :two::five: tijeras que se pueden comprar en :one: transacción. Sin embargo puedes obtener \
    más inciando múltiples transacciones."],
    group: "roadmap"
 },
 {
    id: 10,
    question: "Cuando aparecerán mis tijeras?",
    answer: ["Tus tijeras :v: **aparecerán y serán accesibles immediatamente** y en todos los marketplaces \
    tan pronto hagas el minting. A diferencia de otras colecciones NFT que almacenan las imagenes en el Web2 y por lo tanto pueden \
    cambiar las imagenes y metadatos del mismo \
    después de su creación (con lo que llaman un proceso de ‘revelación’ que puede durar días) nuestra colección \
    es un NFT real :sparkles: que almacena sus elementos en el Web3 donde su valor es la utilidad que aporta al servicio del oráculo más allá de la especulación que su imagen \
    de alta calidad pueda generar, por lo que las imagenes y metadatos relacionados se almacenan en un almacén distribuido inmutable llamado \
    Interplanetary File System ([IPFS](https://ipfs.io)) y disponibles inmediatamente.",
    "Una vez hayas hecho el mint de un nuevo colleccionable, el enlace a esos ficheros \
    se guardará en la cadena de bloques de [Ethereum](https://ethereum.org/es/)...","**P A R A · S I E M P R E** :infinity:",
    "...y por lo tanto tu NFT real estará seguro y será **inalterable**. Visita este artículo de \
    [Pinata.cloud](https://pinata.cloud) donde explica muy bien cuales son los [requimientos de almacenamiento para los medios \
    de un NFT real](https://medium.com/pinata/the-file-requirements-for-nfts-a20ea3ac524b)"],
    group: "wow"
 },
 {
    id: 11,
    question: "Qué puedo hacer con mis Tijeras?",
    answer: ["Tus Tijeras te permitirán formar parte de la comunidad RPS. \
    Si quieres hacer mint de tus Tijeras :v: puedes además optar a los multiples premios que se describen en el Roadmap.\
    Esto incluye poder **participar en una Caza del Tesoro única donde puedes ganar hasta 50 ETH **(5 ETH por cada \
    tijera en equipos de 10 miembros máximo)",
    "Los propietarios de las Tijeras **serán partícipes de los beneficios del futuro oráculo descentralizado** \
    y de forma proporcional **como reconocimiento a su confianza y lealtad al ser los primeros seguidores del proyecto**. \
    Nos encantaría y te recomendamos que mantengas tus Tijeras en el largo plazo \
    debido al NFT Staking y porque creemos enormemente en este proyecto y los resultados que tendrá el Oráculo Distribuido y \
    queremos que seas parte de ese futuro.",
    "Sin embargo, si lo prefieres, también puedes vender tus coleccionables de RPS NFT Art \
    en los mercados secundarios :art: como [OpenSea](https://opensea.io/collection/rps-scissors) o [Rarible](https://rarible.com/rpsnft_art)"],
    group: "benefits"
 },
 {
     id: 12, 
     question: "Qué puedo hacer con mis Tijeras VIP?", 
     answer: ["Tus tijeras VIP te permitirán formar parte de la comunidad RPS y \
     disfrutar de los mismos beneficios que una Tijera normal :v:. Adicionalmente, como miembro VIP :sunglasses:, \
     una vez el primer drop se haya completado, recibirás un increible y generoso \
     premio :gift::sparkles: por cada tarjeta VIP que poseas. Podrás comprobar la recompensa en el contrato inteligente \
     que se publicará en breve."],
     group: "benefits"
 },
 {
     id: 13, 
     question: "Qué pasa si intento hacer mint durante pre-venta pero no soy parte de la whitelist?",
     answer: ["El sitio web consulta en el contrato la lista :page_with_curl: de las cuentas seleccionadas que podrán hacer mint \
     durante la pre-venta.\
     Por lo tanto, no podrás acceder al mint durante la preventa desde el sitio web.",
     "Si intentas hacer mint directamente desde el contrato, también se hace comprobaciones pero puede que incurras en gastos de gas \
     indeseados [Ethereum gas](https://www.youtube.com/watch?v=WWn67-tY2PU&ab_channel=EducaBlock) :fuelpump:, por lo que no recomendamos \
     que se intente en ese caso y esperes a la venta pública. :disappointed:"],
     group: "wow"
 },
 {
     id: 14, 
     question: "Cómo se notificará a los ganadores de los diferentes premios?", 
     answer: ["Los ganadores :trophy: se notificarán mediante [Discord](https://discord.gg/PR4synsBQ5) account."],
     group: "benefits"
 },
 {
     id: 141, 
     question: "Contribuye RPS NFT Art con alguna iniciativa de alguna ONG?", 
     answer: ["Si, lo hacemos gracias a las ventas de NFT.","**5% de los beneficios de las ventas se donan para contribuir con el pueblo de Ucrania** [via 🇺🇦UkraineDAO🇺🇦](https://twitter.com/Ukraine_DAO). \
     El equipo RPS está con el pueblo de Ucrania y no sólo queremos tener un contrato inteligente sino también justo. \
     En nuestro contrato inteligente incluiremos [su dirección](https://etherscan.io/address/0x633b7218644b83d57d90e7299039ebab19698e9c) a fuego"],
     group: "benefits"
 },
 {
     id: 15, 
     question: "Cómo se hará llegar los premios a los ganadores?", 
     answer: ["Se les pedirá a los ganadores que nos proveean su cuenta pública de Ethereum y se les añadirá \
     a una whitelist para que puedan solicitar su premio en el contrato (Escrow contract) para que estos ganadores \
     puedan hacer solicitud mediante el método seguro de pago pull payment. Una vez añadidos a la whitelist del contrato, \
     les explicaremos detalladamente cómo solicitar el pago al contrato de manera segura en sus cuentas :money_mouth_face:.",
     "Nótese que aunque se deberá invertir una cantidad limitada de [Ethereum gas](https://www.youtube.com/watch?v=WWn67-tY2PU&ab_channel=EducaBlock) :fuelpump: \
     por parte de los ganadores, esta es la forma más segura y descentralizada para hacer los pagos evitando \
     el conocido problema de seguridad de re-entrancia :shield:. Si quieres saber más, lee por favor \
     [este artículo](https://blog.openzeppelin.com/reentrancy-after-istanbul/)."],
     group: "benefits"
 },
 {
     id: 16, 
     question: "Podré ver el contrato inteligente que hace el mint?",
     answer: ["Si, el código Solidity del contrato de Tijeras :memo: **está disponible públicamente \
     [aquí](https://etherscan.io/address/0xb8B26224674d346300464CD0373774CcFAEdBffF#code) y antes de que hagas el minting** \
     de forma que puedas revisarlo :mag_right: y validar las reglas y premios que se aplicarán automáticamente en la cadena de bloques \
     tal y como se explica en nuestro [roadmap](/roadmap). \
     Además, el contrato ha sido validado y auditado por MythX.io y no se ha podido encontrar \
     ninguna vulnerabilidad de seguridad. **Los reportes de la auditoría están disponibles [aquí](https://drive.google.com/file/d/1GG4pvLNb0YYSarhmDlDKC0O7IWvhu_Ll/view?usp=sharing).**.",
     "Aunque recomendamos que se haga el minting desde nuestra web, también puedes acceder al contrato desde el enlace \
     del contrato que proveemos [aquí](https://etherscan.io/address/0xb8B26224674d346300464CD0373774CcFAEdBffF#writeContract) y hacer el mint desde ahí. Por favor, no uses \
     ningún otro enlace que no se haya proporcionado desde un canal oficial y seguro."],
     group: "security"
 },
 {
     id: 17, 
     question: "Cuando estarán disponibles los papeles y las piedras?", 
     answer: ["Podeis ver una estimación para los próximos drops en el [roadmap](/roadmap) :punch::raised_hand_with_fingers_splayed: \
     Tan pronto tengamos más detalles have more details haremos una actualización y lo comunicaremos por los canales oficiales de nuestras RRSS."],
     group: "roadmap"
 },
 {
     id: 18, 
     question: "Tendré alguna ventaja en próximos drops si compro tijeras ahora?", 
     answer: ["Por supuesto. Tenemos muy en cuenta a nuestros primeros miembros de la comunidad RPS. Su confianza y su lealtad se verá \
     recompensada en los próximos drops con ventajas especiales."],
     group: "benefits"
 },
 {
     id: 19, 
     question: "Cómo contacto el equipo si tengo más preguntas?", 
     answer: ["Puedes seguirnos en nuestras redes sociales en [Twitter](https://twitter.com/RpsNFT_Art) o [Instagram](https://www.instagram.com/rpsnft_art/), atender las diferentes sesiones AMAs (Ask Me Anything) que hemos hecho y que \
     haremos o contactarnos através de [Discord](https://discord.gg/PR4synsBQ5) y estaremos muy contentos de contestar cualquier pregunta \
     que puedas tener."],
     group: "wow"
 },
 {
     id: 20, 
     question: "Cuando estará disponbile el oráculo distribuido RPS?", 
     answer: ["Después del segundo drop (Papeles), el equipo RPS comenzará a trabajar en la implementación del \
     servicio de oráculo distribuido RPS NFT Art para que esté disponible para la comunidad y la industria tan pronto el tercer drop haya \
     finalizado. Fecha final :date: a confirmar."],
     group: "roadmap"
 },
 {
    id: 21,
    question: "Puede acceder a más FAQs e información?",
    answer: ["**Por supuesto!**. Tienes acceso a la lista completa de FAQs a un sólo click en la página de [ayuda](/help) \
    de esta web.","Allí podrás encontrar preguntas y respuestas adicionales acerca de los precios, roadmpa, beneficios y temas de seguridad.",
   "También nos puedes visitar en [medium](https://medium.com/@rpsnft_art) donde publicamos artículos que intentan dar más detalles técnicos de nuestra visión."],
    group: "wow"
 }
]