import React from 'react';
import ReactGA from 'react-ga'

function ContractAddr() {
  const evtEtherScanVisit = _label => {
    ReactGA.event({
      category: 'ETHERSCAN',
      action: 'VISIT',
      label: _label
    })
  }

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-4 md:pb-20">
            <h2 className="h2" data-aos="fade-up">Scissors Smart Contract Code</h2>
            <label className="text-yellow-400">Scissors Contract Address: <strong>
              <a href="https://etherscan.io/address/0xb8B26224674d346300464CD0373774CcFAEdBffF"
                 target="_blank" rel="noreferrer" 
                 onClick = {() => evtEtherScanVisit('CONTRACT')}>0xb8B26224674d346300464CD0373774CcFAEdBffF</a></strong></label>
          </div>

          {/* EtherScan */}
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">

              {/* EtherScan Contract Address */}
              <article className="flex flex-col h-full" data-aos="fade-up">
                <header>
                  <a className="block mb-6" href="https://etherscan.io/address/0xb8B26224674d346300464CD0373774CcFAEdBffF#code"  
                      target="_blank" rel="noreferrer" 
                      onClick = {() => evtEtherScanVisit('SRCCODE')}>
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-y-10 w-fit h-fit scale-90 object-cover bg-white transform hover:scale-110 transition duration-700 ease-out" 
                           src={require('../images/etherscancode.png').default} 
                           width="352" height="198" alt="EtherScan" />
                    </figure>
                  </a>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-purple-600 hover:bg-white transition duration-150 ease-in-out"
                           href="https://etherscan.io/address/0xb8B26224674d346300464CD0373774CcFAEdBffF#code"  
                           target="_blank" rel="noreferrer" 
                           onClick = {() => evtEtherScanVisit('ETHERSCAN')}>Verified Source Code</a>
                      </li>                      
                    </ul>
                  </div>                  
                </header>                                
              </article>


            </div>
          </div>          
        </div>
      </div>
    </section>
  );
}

export default ContractAddr;
