import { concepts as conceptsEn } from '../data/en/concept'
import { concepts as conceptsEs } from '../data/es/concept'

const filter_concept = (data,group) => {    
    return (

      data.filter((data,key) => {     
        return(data.group.localeCompare(group)?false:true);
      })  
    )
  }


export function useFilteredConcepts(lang) {
  
  let concepts = conceptsEn;

  switch(lang) {
    case "es":
      concepts = conceptsEs;  
      break;    
  }

  const filteredConcepts =  [
    filter_concept(concepts,'main'), // Concepts - Main
    filter_concept(concepts,'mission'), // Concepts - Mission    
    filter_concept(concepts,'additional'), // Concepts - Additional
    filter_concept(concepts,'differentiators'), // Concepts - Differentiators 
    filter_concept(concepts,'key features'), // Concepts - Key Features
  ];
  
  return filteredConcepts;
}