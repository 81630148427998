import React, { useState,useEffect,useContext } from 'react';
import { useFilteredFaqs } from '../utils/FilteredFaqs'
import { UserContext } from '../context/UserContext'

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';
import { show_paragraph } from '../utils/Markdown'

import './Help.css'
import { Helmet } from 'react-helmet';

const faqTitles = [
  ['Ways of Working','March 02, 2022'],
  ['Price','March 02, 2022'],
  ['Roadmap','March 02, 2022'],
  ['Benefits','March 02, 2022'],
  ['Security','March 02, 2022']
];

function Help() {

  const [page, setPage] = useState(1);
  const { browserLang } = useContext(UserContext);
  const filteredFaqs = useFilteredFaqs(browserLang);

  let filteredFaq=filteredFaqs[page-1];

  const mainUL = (
    <ul className="-my-4">
    {
      filteredFaq.map((data,key) => {
        return(
          <li className="py-4" key={key}>
            <h4 className="text-xl font-medium mb-2 text-indigo-400">{data.question}</h4>
            { show_paragraph(data.answer,data.answerClassName) }
          </li>
        );
      })
    }
    </ul>
  );
  
  const faqItem = (_page) => {
    return (
        <div className={page !== _page ? 'hidden' : undefined}>
          <div className="mb-8">
            <h2 className="h2 mb-4">RPS Scissors - {faqTitles[page-1][0]}</h2>
            <p className="text-gray-400">Last updated - <span className="text-purple-600">{faqTitles[page-1][1]}</span></p>
          </div>

          {mainUL}
        </div>
    );
  } 

  /* Navigation Li item */
  const navLi = (_page) => {
    return(
        <li className="py-2 border-b border-gray-800">
          <a
            className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === _page && 'text-purple-600'}`}
            href="#0"
            onClick={(e) => { e.preventDefault(); setPage(_page); }}
          >
            <span>RPS Scissors - {faqTitles[_page-1][0]}</span>
            <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
            </svg>
          </a>
        </li>
    );
  }

  // change filterFaq
  useEffect(() => {
    filteredFaq = filteredFaqs[page-1];  
  }, [page]);

  return (
    <>
    <Helmet>
      <title>RPS NFT Art - FAQs</title>  
      <meta name="description" content="Scissors FAQs of Rock Scissors Paper - NFT for RPS Oracle Distributed Service."/>      
    </Helmet>

    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        {/*  Page sections */}
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="flex flex-col md:flex-row">

              {/* Main content */}
              <main className="md:flex-auto md:pl-10 order-1" data-aos="fade-up">
                
                {/* Ways of Working */}
                { faqItem(1) } 

                {/* Price */}
                { faqItem(2) } 

                {/* Roadmap */}
                { faqItem(3) } 

                {/* Benefits */}
                { faqItem(4) } 

                {/* Security FAQs */}
                { faqItem(5) } 

              </main>

              {/* Nav sidebar */}
              <aside className="md:w-64 mb-16 md:mb-0 md:mr-10 md:flex-shrink-0" data-aos="fade-up" data-aos-delay="200">
                <h4 className="text-lg font-medium px-3 pb-3 border-b border-gray-800">Choose a category</h4>
                <nav>
                  <ul>
                    { navLi(1) }                    
                    { navLi(2) }
                    { navLi(3) }
                    { navLi(4) }
                    { navLi(5) }
                  </ul>
                </nav>
              </aside>

            </div>
          </div>
        </div>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
    </>
  );
}

export default Help;