export const roadmaps = [
    {
        id: 1,
        title: 'Creating Community',
        body: ["We want to create a big community and we want **to reward members :gift::gift::gift: that help us to achieve \
        our goal :goal_net:**. Once we achieve the 50% of minting :thermometer:, the :three: community members who have \
        brought more users to our discord will be rewarded with:",
        "- First: **2.5 ETH**",
        "- Second: **1 ETH**",
        "- Third: **0.5 ETH**"],
        image: 'community.jpg',
        group: 'summary'
    },
    {
        id: 2,
        title: 'VIPs',
        body: ["This project is too good to be only ours, we are looking for VIP :sunglasses: community members to join us in \
        this journey. **:two::zero: special scissors NFT`s will be published at [OpenSea](https://opensea.io/collection/rps-scissors)**.",
        "**The owners of these cards will get very generous benefits** very similar to the ones of the \
        core team members."],
        image: 'vip.jpg',
        group: 'summary'
    },
    {
        id: 3,
        title: 'Pre-sales',
        body: ["We want to guarantee spots :seat::seat::seat::popcorn: for our community members.\
        During :three: days a special scissor :v: will be available for bidding at [OpenSea](https://opensea.io/collection/rps-scissors). After this period, \
        the best bidder will get the card and the best :two::five::zero: bidders will be whitelisted :page_with_curl: for presales.",
        "Also, we want to encourage creativity and a sense of community, so the :two::five: most active \
        :runner: and original :clown_face: community members will be rewarded with a presales list place."],
        image: 'presales.jpg',
        group: 'summary'
    },
    {
        id: 8,        
        date: 'February 2022',
        body: ["Creating Community. We want to reward members that help us to grow our community.  **At 50% of minting**, \
        the 3 community members who have brought more users to our discord will be rewarded as follows:",
        "- First: **2.5 ETH**",
        "- Second: **1 ETH**",
        "- Third: **0.5 ETH**"],
        group: 'dates q1'
    },
    {
        id: 9,
        date: 'March 2022',
        body: ["VIP Scissors: **20 special scissors NFT's will be published** at [OpenSea](https://opensea.io/collection/rps-scissors). \
            NFT VIP community members will get very generous benefits (very similar to the core team members)."],
        group: 'dates q1'
    },
    {
        id: 10,        
        date: 'March 2022',
        body: ["Pre-sales Whitelists spots.","During 3 days a **special scissor** will be available for bidding at [OpenSea](https://opensea.io/collection/rps-scissors).\
        Best bidder will get the card and **the best 250 bidders** will be whitelisted for presales. \
        Also we want to encourage creativity and sense of community, so will most active and original \
        community members will get presales list spots (1500 additional spots)."],
        group: 'dates q1'
    },
    {
        id: 12,        
        date: '18-March-2022',
        body: ["**Pre-sales starts**. Whitelisted community members for presales can start minting their first Scissors✌️ during one week (Max. of 2 per account)"],
        group: 'dates q1'
    },    
    {
        id: 121,        
        date: '25-March-2022',
        body: ["✨✨ **Public Sales Starts** ✨✨"],        
        group: 'dates q1'
    },
    ,    
    {
        id: 13,        
        date: '25% minted',
        body: ["More and more scissors appear in the metaverse. **When 25% sales are reached**, five special scissors make the entrance in the metaverse. \
        **5 NFT airdropped** and **5 ETH in the on-chain Treasure pot**"],        
        group: 'dates q1'
    },    
    {
        id: 14,        
        date: '50% minted',
        body: ["Half of all Scissors have arrived to the metaverse and conflicts will start. **4 ETH distributed to the community members** that have brought \
        more new users and **10 ETH are added to the on-chain Treasure pot**"],        
        group: 'dates q1'
    },    
    {
        id: 15,        
        date: '75% minted',
        body: ["Strange things start happening... On-chain Treasure pot continues increasing. **5 ETH are airdropped among community members** and \
        on-chain treasure **pot adds 15 ETH more**"],        
        group: 'dates q1'
    },    
    {
        id: 16,        
        date: '100% minted',
        body: ["Scissors organize themselves to resolve the mystery **20 ETH are added** to the treasure pot, **5% of sales revenue \
        is donated to Support Ukraine country** [via 🇺🇦UkraineDAO🇺🇦](https://twitter.com/Ukraine_DAO). \
        RPS Team fully supports Ukraine and we want to have an smart but fair contract. We will use [their address](https://etherscan.io/address/0x633b7218644b83d57d90e7299039ebab19698e9c) hardcoded in our Smart Contract",
        "**TREASURE HUNT of the 50ETH START ON CHAIN!!**"],        
        group: 'dates q1'
    },
    {
        id: 17,
        date: 'Q2 2022',
        body: ["Papers land in the metaverse. **10k papers find also their way in**. Each paper is a unique piece of art that belongs to a \
        mysterious intellectual character. Conflicts will start happening. Influence of design and \
        personalization will be key to move forward in the adventure."],        
        group: 'dates q1'
    },
    {
        id: 18,        
        date: 'Q3 2022',
        body: ['Rocks revenge',"**10k rocks hit the metaverse**. Rocks have always being seen as the mysterious bad guys.\
        Who is wrong or who is right? Let's the distributed RPS oracle decide..."],        
        group: 'dates q1'
    },    
    {
        id: 19,        
        date: 'Q4 2022',
        body: ["ERC-20 **RPS Token** is created along the **first Oracle Distributed Service based in RPS**.",
        "**NFT staking rewards among RPS collection community owners** are activated in a new smart contract."],        
        group: 'dates q1'
    }
]