
import { useState,useEffect } from 'react';
import {
  useLocation
} from 'react-router-dom';
import AppRouter from './routes/AppRouter'
import { UserContext } from './context/UserContext'
import { Helmet } from "react-helmet"
import { doConnectAccount, AreWeInPreSales , AmIinPreSalesList, AreWeInPublicSales, 
         IsPresalesFinished, IsPublicSalesFinished, AmIinWhiteList, IsContractPaused } from './components/elements/contractHelper';

import './css/style.scss';
import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';
import ReactGA from 'react-ga';

import detectBrowserLanguage from 'detect-browser-language';

function App() {

  ReactGA.initialize('UA-220643900-1')

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');

    ReactGA.pageview(location.pathname);

  }, [location.pathname]); // triggered on route change

  const [accounts, setAccounts] = useState(() => {
    // getting stored value
    const saved = sessionStorage.getItem("accounts");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [browserLang, setBrowserLang] = useState("en");
  const [scissors, setScissors] = useState(null);
  const [maxDrop, setMaxDrop] = useState(null)
  const [lastId, setLastId] = useState(0)
  const [canIMintWhiteList, setCanIMintWhiteList] = useState(false);
  const [canIMintPresales, setCanIMintPresales] = useState(false);
  const [inPublicSales, setInPublicSales] = useState(false);
  const [inPreSales, setInPreSales] = useState(false);
  const [presalesFinished,setPresalesFinished] = useState(false);
  const [publicsalesFinished,setPublicsalesFinished] = useState(false);
  const [isPaused,setIsPaused] = useState(true)
  
  // Save changes for accounts
  useEffect(() => {
    sessionStorage.setItem('accounts', JSON.stringify(accounts));
  }, [accounts]);

  // Retrieve when refreshing
  useEffect(() => {
    const _accounts = sessionStorage.getItem('accounts');
  
    if(_accounts) {
      //setAccounts(JSON.parse(_accounts))      
      doConnectAccount(setAccounts,setScissors, setMaxDrop);
    }    

    setBrowserLang(detectBrowserLanguage());
    console.log("Browser lang = "+detectBrowserLanguage());

  }, []);

  const refreshBooleans = async () => {
    if(scissors!=null) {
      AreWeInPreSales(accounts, scissors, setInPreSales); 
      AmIinWhiteList(accounts,scissors,setCanIMintWhiteList);  
      AmIinPreSalesList(accounts, scissors, setCanIMintPresales);
      AreWeInPublicSales(accounts,scissors, setInPublicSales);
      IsPresalesFinished(accounts, scissors, setPresalesFinished);
      IsPublicSalesFinished(accounts, scissors, setPublicsalesFinished);
      IsContractPaused(accounts, scissors, setIsPaused);
    }
  }

  setInterval(refreshBooleans, 1000);
  
  return (
    <UserContext.Provider value={{accounts, setAccounts,
                                 scissors, setScissors,
                                 browserLang, setBrowserLang,
                                 maxDrop, setMaxDrop,
                                 lastId, setLastId,
                                 canIMintWhiteList, setCanIMintWhiteList,
                                 canIMintPresales, setCanIMintPresales,
                                 inPreSales, setInPreSales,
                                 inPublicSales, setInPublicSales,
                                 presalesFinished,setPresalesFinished,
                                 publicsalesFinished,setPublicsalesFinished,
                                 isPaused, setIsPaused}}>
      <Helmet>
        <title>RPS NFT Art</title>  
        <meta name="description" content="Rock Scissors Paper NFT for RPS Oracle Distributed Service. Bring unbiased decision power to the web3 community."/>      
      </Helmet>

      <AppRouter/>
     
    </UserContext.Provider>

  );
}

export default App;
