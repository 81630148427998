export const faqs = [
 {
    id: 1,
    question: "How can I get my Scissor?",
    answer:["Scissors :v:, will be available for purchase on our website through an initial sale (minting). \
    At the time of purchase, a randomly selected scissor will be minted on the [Ethereum blockchain](https://ethereum.org/en/) and \
    delivered to your wallet :purse:."],
    group: "wow"
 },
 {
    id: 2,
    question: "But, how does it work?",
    answer:["In order to mint a scissor you will have to install a [MetaMask extension](https://metamask.io/faqs/) in your browser and link your crypto\
     wallet to it. Ensure you have enough ETH in your wallet to cover the cost of a Scissor plus the associated \
     [Ethereum gas](https://www.youtube.com/watch?v=3ehaSqwUZ0s&ab_channel=WhiteboardCrypto) :fuelpump: fees.",
     "During sales, enter a quantity of Scissors, press the “Mint” button on our website, approve the desired transaction on MetaMask and you are all \
     set :wink: If you do not know how to setup Metamask :fox_face: wallet, please refer to \
     [this video](https://youtu.be/9yrj2iSuI38?t=235)."],
     group: "wow"
 },
 {
    id: 3,
    question: "What will be the price of a Scissor?",
    answer: ["The price of a scissor :label: during pre-sales airdrop will be **0,055 ETH + gas**. The price during \
    **public sales will be 0.07 ETH + gas**."],
    group: "price"
 },
 {
    id: 4,
    question: "What will be the price of VIP scissor?",
    answer: ["The price of a each VIP scissor :sunglasses: **will be 0,15 ETH + gas** :label:. These scissors will be available \
    at [OpenSea.io](https://opensea.io/collection/rps-scissors)."],
    group: "price"
 },
 {
    id: 5,
    question: "What is the total supply?",
    answer: ["As anything valuable :amphora:, scarcity is a must. A maximum total of **10,000 unique scissors** NFTs will be created \
    in the collection."],
    group: "wow"
 },
 {
    id: 6,
    question: "When will be the official launch date?",
    answer: ["We will launch public sales on :date: **March 25th 2022** :tada:. Watch out :mag: for the public countdown!"],
    group: "roadmap"
 },
 {
    id: 7,
    question: "When is the pre-sale?",
    answer: ["The pre-sales date is :date: **March 18th 2022**. Watch out :eyes: for the pre-sales countdown!"],
    group: "roadmap"
 },
 {
    id: 8,
    question: "How many scissors can I purchase in one transaction during pre-sales?",
    answer: ["There is a limit of :two: **Scissors** that can be purchased **per wallet** in :one: or :two: transactions. \
    During pre-sales you cannot hold more than :two: per wallet."],
    group: "roadmap"
 },
 {
    id: 9,
    question: "How many scissors can I purchase in one transaction during public sales?",
    answer: ["There is a limit of :two::five: scissors that can be purchased in :one: transaction. However you can get more \
    by initiating multiple transactions."],
    group: "roadmap"
 },
 {
    id: 10,
    question: "When will my Scissors be revealed?",
    answer: ["Your Scissors :v: will be **revealed and accessible immediately** and in all the marketplaces \
    as soon as you mint them. Unlike other NFT collections that store media files in Web2 and therefore can change your NFT media and metadata files \
    after minting them (with a so-called ‘reveal process’ that can last several days) our scissors collection \
    is a real NFT :sparkles: storing media in Web3, where its value is the utility that it brings to the oracle aside of the especulation that its high quality image \
    may generate, so our images and related metadata files are stored in the immutable Interplanetary \
    File System ([IPFS](https://ipfs.io)) and available immediately.",
    "Once you mint a new collectible, the link to those files \
    will be persisted in the [Ethereum blockchain](https://ethereum.org/en/)...","**F O R E V E R** :infinity:",
    "...and therefore your real NFT will be secure and **unalterable**. See this article from \
    [Pinata.cloud](https://pinata.cloud) that explains very well what are the storage [requirements \
    of a real NFT](https://medium.com/pinata/the-file-requirements-for-nfts-a20ea3ac524b)"],
    group: "wow"
 },
 {
    id: 11,
    question: "What can I do with my Scissors?",
    answer: ["Your scissors will allow you to be part of the RPS community. \
    If you mint a Scissor :v: you can get one of the multiple prizes that are described in the roadmap.That includes \
    **participating in a unique Treasure Hunt on-chain where you can win up to 50 ETH** \
    (5 ETH per each scissor in a team of max 10 members).",    
    "Scissors holders will benefit from the future decentralized oracle service offering \
    in a proportional way as a recognition for their trust and loyalty as being the early supporters of the project. \
    We would also love and would recommend you hold them for the long run so you can benefit from future benefits with us, the \
    NFT staking benefits and because we are highly convinced of the success of this project and the positive results of our \
    Distributed Oracle and we want you to be part of our future.",
    "However, if you decide so, you can also trade with your RPS collectibles in \
    secondary marketplaces :art: like [OpenSea](https://opensea.io/collection/rps-scissors) or [Rarible](https://rarible.com/rpsnft_art)"],
    group: "benefits"
 },
 {
     id: 12, 
     question: "What can I do with my VIP scissors?", 
     answer: ["Your VIP scissors will allow you to be part of the RPS community and \
     enjoy the same benefits of a normal Scissor :v:. Additionally, as a VIP member :sunglasses:, \
     once the Scissor drop is sold out, you will received an incredible and generouse \
     prize :gift::sparkles: for each VIP card that you hold. You will be able to check this in the Smart Contract \
     that will be published soon"],
     group: "benefits"
 },
 {
     id: 13, 
     question: "What happens if I try to mint during presales but I’m not part of the presales list?",
     answer: ["The website controls the whitelist :page_with_curl: of the selected accounts that will be able to mint \
     during pre-sales.\
     So you will not be able to access the pre-sales mint action from the website.",
     "If you try minting directly from the contract, you may try but same controls are happening in the smart \
     contract. However, that will incur [Ethereum gas](https://www.youtube.com/watch?v=3ehaSqwUZ0s&ab_channel=WhiteboardCrypto) \
     fees :fuelpump:, so we do not recommend you trying to mint \
     in this case and that you wait for the public sales. :disappointed:"],
     group: "wow"
 },
 {
     id: 14, 
     question: "How will winners of different prizes be notified?", 
     answer: ["Winners :trophy: will be notified through our [Discord](https://discord.gg/PR4synsBQ5) account."],
     group: "benefits"
 },
 {
     id: 141, 
     question: "Is RPS NFT Art supporting any NGO initiative?", 
     answer: ["Yes, our community contributes thanks to the NFT sales.","**5% of sales revenue \
     is donated to Support Ukraine country** [via 🇺🇦UkraineDAO🇺🇦](https://twitter.com/Ukraine_DAO). \
     RPS Team fully supports Ukraine and we want to have an smart but fair contract. We will use [their address](https://etherscan.io/address/0x633b7218644b83d57d90e7299039ebab19698e9c) hardcoded in our Smart Contract"],
     group: "benefits"
 },
 {
     id: 15, 
     question: "How will prizes be made available to the winners?", 
     answer: ["Winners will be asked to provide their Ethereum public keys and those will be \
     added to a whitelist that can request their prize (Escrow contract) in order these final \
     users can make a payment request (pull payment). Once they have been added to the contract \
     whitelist we will explain them how to do their request to securely obtain their reward in \
     their accounts :money_mouth_face:.",
     "Note that although a limited amount of [Ethereum gas](https://www.youtube.com/watch?v=3ehaSqwUZ0s&ab_channel=WhiteboardCrypto) :fuelpump: \
     will be paid by the winners, this is the most secure method to perform payments avoiding the \
     well known re-entrance security issue :shield:. If you want to know more about it, please read \
     [this article](https://blog.openzeppelin.com/reentrancy-after-istanbul/)."],
     group: "benefits"
 },
 {
     id: 16, 
     question: "Will I be able to see the Scissors Minting Contract?",
     answer: ["Yes, the Solidity code for the Scissors contract :memo: **is publicly available \
     [here](https://etherscan.io/address/0xb8B26224674d346300464CD0373774CcFAEdBffF#code) before you mint** \
     so you can review it :mag_right: so you can validate the rules in the blockchain are the ones explained in \
     our [roadmap](/roadmap). Además, the contract has been validated and audited by MythX.io with no \
     security vulnerability found. **Audit reports are available [here](https://drive.google.com/file/d/1GG4pvLNb0YYSarhmDlDKC0O7IWvhu_Ll/view?usp=sharing).**.",
     "Although we recommend you mint from our website, you can also access the contract from the provided \
     link and mint from the link we provide to the smart contract [here](https://etherscan.io/address/0xb8B26224674d346300464CD0373774CcFAEdBffF#writeContract). Please, do not use any other link as it may not be our official contract."],
     group: "security"
 },
 {
     id: 17, 
     question: "When will papers and rocks drops happen?", 
     answer: ["You can see an estimation of the following drops in our [roadmap](/roadmap) :punch::raised_hand_with_fingers_splayed: \
     As soon as we have more specific details we will provide updates and communicate it via our official channels thru our Social Networks."],
     group: "roadmap"
 },
 {
     id: 18, 
     question: "Will I have any advantage if I buy scissors for future drops?", 
     answer: ["Yes, absolutely. We highly appreciate and keep into account our early adopters and first RPS community members. Your trust and \
     loyalty will be rewarded during next drops with special benefits."],
     group: "benefits"
 },
 {
     id: 19, 
     question: "How do I contact the team if I have more questions?", 
     answer: ["You can follow us thru social networks in [Twitter](https://twitter.com/RpsNFT_Art) or \
     [Instagram](https://www.instagram.com/rpsnft_art/), attend the several AMA (Ask Me Anything) sessions we have done and that we will \
     attend or contact us directly at [Discord](https://discord.gg/PR4synsBQ5) and we will be happy to answer any \
     question you may have."],
     group: "wow"
 },
 {
     id: 20, 
     question: "When will the distributed oracle service RPS be available?", 
     answer: ["After the 2nd drop (Paper drop), the RPS team will start working on the implementation of the \
     RPS NFT Art distributed oracle service to make it available for the community and industry once 3rd drop is finished. Final date :date: to be confirmed."],
     group: "roadmap"
 },
 {
    id: 21,
    question: "Can I have access to more FAQs?",
    answer: ["**Absolutely!** You have access to the entire FAQs list by clicking in the [help page](/help) \
    of this website.","There you will find additional questions about Prices, Roadmap, Benefits and Security.",
    "You can visit us at [medium](https://medium.com/@rpsnft_art) too where we publish technical articles to explain a little bit more our vision."],
    group: "wow"
 }
]