import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import emoji from 'remark-emoji'
import breaks from 'remark-breaks'

export const show_paragraph = (body,a_style=undefined) => {

    return body.map((a_paragraph,index) => {
      return(
        <p className="text-gray-400 mb-3" data-aos="fade-up" data-aos-delay="200">
          <ReactMarkdown 
            remarkPlugins={[gfm, breaks, emoji]}
            className={`py-3 reactMarkDown ${a_style && a_style}`}>
              {a_paragraph}
          </ReactMarkdown>
        </p>
      );
    }
    )
  }
