export const concepts = [
    {
       id: 1,
       title: "RPS Web3 Distributed Oracle",       
       body:[
       "Thousands of decisions are made everyday by people and applications and, **in the metaverse, it will just be the same.** \
       The future is on the web3 and metaverse but smart contracts can't get data today directly from the real world and they \
       need oracle services in the chain to work.",
       "**RPS mission is to bring unbiased decision power to the web3  community,** specifically by providing web3 distributed \
       oracle service to the society. We want to do this in the easiest  way possible using as an example the  popular game \
       Rock Paper Scissors 👊✋✌️.",
       "Thanks to the RPS distributed oracle services DApps🔮, any community, DAO, sport game or gambling applications will be \
       able to resolve disputes in an unbiased way in the chain. **Any decision taken thanks to RPS will be stored in the \
       the blockchain** (PoS Blockchain) and anyone will be able to request evidence results \
       for future proof.",
       "RPS is a multi-collection of 3 drops containing **10k utility NFTS** created on Ethereum [ERC-721 contract](https://youtu.be/HTm-1JtI0fA).\
       RPS members will be able to participate in contests, use their collectibles to record decisions on-chain and \
       also will **benefit from the pay-per-use fee whenever RPS oracle distributed services are used by other communities or DApps in the metaverse.**"],
       group: "main"
    },
    {
        id: 3,        
        title:"Our Mission", 
        icon:"user-astronaut",
        bodyClassName: "mission",       
        body: ["“To facilitate unbiased decision making in the Web3 ecosystem”"],
        group: "mission"
    },
    {
        id: 4,        
        title:"Our Vision",
        icon:"glasses",
        bodyClassName: "vision",
        body: ["To be the Web3 oracle service of choice for any decentralized decision in any metaverse."],
        group: "mission"
    },
    {
        id: 5,
        subtitle: "Drop 1",
        title: "10,000 Scissors",        
        body: ["Hands can be symbols of peace, love and war and are a sign of identity and **decision power**.",
        "**Drop 1  is a collection of  10K  unique scissors ✂️ made out of aesthetic genderfluid hands.**",
        "Each scissor is a  3D detailed hand that belongs to a mysterious celebrity character and has been \
        generated from a mix of **more than 200 traits** with references from urban style and retro-futurism \
        to make each scissor a unique digital art work piece.",        
        "All scissors matter but some are rarer than others. Find your fit in the metaverse and be part of \
        the Web3 distributed oracle decission power."
        ],
        image: "scissors-01.png",
        group: "additional"
    },
    {
        id: 8,
        title: "For the community members",
        bodyClassName: "features",
        body: ["✔️ Spots for **VIP owners**",
        "✔️ **59 ETH in prizes** just in the first drop",
        "🔥 **On-chain Treasure hunt**",
        "✔️ Power to **influence design of future drops**",
        "✔️ **Personalization** in future drops",
        "✔️ **5% of each drop revenue for charity donation**",
        "👍 Be part of the creation of the first **web3 RPS oracle service**",
        "✔️ **NFT Staking rewards**: NFT Owners will have **passive income benefits** from the service fees of \
        of future web3 RPS oracle services to the DApps users.",
        "· **50% of oracle service revenue** will be assigned by smart contract \
        to NFT owners with the following weights: 25% to Scissors owners, 15% to Paper owners, 10% to Rock owners",
        "· **25% of oracle service revenue** will be assigned to RSP players taking decissions on-chain",
        "· **Remaining 25% of revenue** will be assigned to co-founders of RPS"],
        group: "differentiators"
    },
    {
        id: 9,
        title: "Cutting Edge Technical Differentiators",
        bodyClassName: "features",
        body: ["✔️ **Real NFT ERC 721** (images not in centralized private servers)",
        "👛 **ERC-20 Token creation** (fungible token for the future service payment)",
        "✔️ Decission Power **Oracle Social Distributed Service** based",
        "✔️ **Personalization** (starting in second drop)",
        "🔐 **Extremely Secure Smart Contract** (Consensys [MythX security audit](https://drive.google.com/file/d/1GG4pvLNb0YYSarhmDlDKC0O7IWvhu_Ll/view?usp=sharing))",        
        "⚖️ [Use of game theory RPS mathematical models](https://medium.com/@rpsnft_art/rps-nft-art-as-a-decentralized-social-oracle-service-749aa8056fe5) for un-biased but fair (zero-sum evolutionary game type) in the decission taking,\
        in favor of pure coin toss flipping as used by other existing oracles services"],
        group: "differentiators"
    },
    {
        id: 10,
        title: "For the Industry",
        bodyClassName: "features",
        body: ["✔️ **Collaborations** with Brands and Artists",
        "🔮 **WEB3 RPS oracle distributed service** for other web3 DApps benefit (DAOs, marketplaces, games, metaverses, etc...)"],
        group: "differentiators"
    }
]