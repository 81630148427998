
import React, { useContext } from "react"
import Button from "./Button"
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext'
import { doConnectAccount }  from './contractHelper'
import Banner from './Banner'

const MetaMaskConn = ({
    presales:preSales,
    publicsales:publicSales
    ,...props
}) => {

    const { browserLang, accounts,scissors,setAccounts, setScissors, 
            setMaxDrop,
            canIMintPresales, setCanIMintPresales,
            inPublicSales, setInPublicSales,
            inPreSales, setInPreSales,
            presalesFinished, setPresalesFinished,
            publicsalesFinished, setPublicsalesFinished
        } = useContext(UserContext);
    
    // Method to connect the account relying on connectMetamask()
    const connectAccount = async (e) => {

        if(e)
            e.preventDefault()

        const _accounts = doConnectAccount(setAccounts,setScissors, setMaxDrop);

        return _accounts;
    }
    
    const isAccountsUnlocked = accounts ? accounts.length > 0 : false 
        
    if(!isAccountsUnlocked) {
        return (
            <div>
                <Button variant="primary" size="lg" className="inline-flex text-center text-gray-100 ml-5 py-1 px-3 rounded-full items-center justify-center bg-purple-600 hover:bg-white transition duration-150 ease-in-out"
                    onClick={(e) => connectAccount(e)}>
                {browserLang==='es'?"Conecta tu billetera":"Connect your Wallet!"}
                </Button> 
            </div>);
    } 

    //console.log("CanIMintInPresales? = "+canIMintPresales)
    
    if(inPreSales) {
        if(canIMintPresales) {
            return (
                <div>
                    <Link to="/mint" className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3">
                    {browserLang==='es'?"Mint de Tus Tijeras (Pre-Sales)":"Mint Your Scissors (Pre-Sales)"}
                    </Link>
                </div>
                )
        } else {
            return (
                <div>
                    <Banner variant="danger" heading_class="text-dark" heading="Account cannot mint during pre-sales">
                        <p><strong>{browserLang==='es'?"Esta cuenta":"This account"} ({accounts[0]}) {browserLang==='es'?"o bien no se ha seleccionado para la pre-venta O ya ha llegado al máximo permitido por cuenta durante el periodo de preventa":"either has not been selected for pre-sales OR it has already reached the maximum per-wallet amount allowed during the presales period)"}.</strong>
                        </p> 
                        <p>{browserLang==='es'?"Por favor, espera hasta el periodo de venta pública que se abrirá en breve":"Please wait until public sales period is open soon!"}</p>           
                    </Banner>
                </div>
            )
        }
    } else if(inPublicSales) {
        return (
            <div>
                <p></p>
                <Link to="/mint" className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3">
                {browserLang==='es'?"Mint de tus Tijeras (Venta Pública)":"Mint Your Scissors (Public Sales)"}
                </Link>
            </div>
            )
    } else if(presalesFinished && !publicsalesFinished) {
        return (
            <div>
                <Banner variant="danger" heading_class="text-dark" heading="Public Sales Has not Started">
                    <p><strong>{browserLang==='es'?"El periodo de venta pública no ha comenzado todavía.":"Public Sales period has not started yet."}</strong>
                    </p> 
                    <p>{browserLang==='es'?"Por favor, mantente al corriente hasta que el periodo de venta pública abra en breve":"Please stay tuned until sales period is open soon!"}</p>           
                </Banner>
            </div>
        )
    } else if(!presalesFinished && !publicsalesFinished) {
        return (
            <div>
                <Banner variant="danger" heading_class="text-dark" heading="Pre-Sales Has not Started">
                    <p><strong>{browserLang==='es'?"El periodo de pre-venta no ha comenzado aún":"Pre-Sales period has not started yet."}</strong>
                    </p> 
                    <p>{browserLang==='es'?"Por favor, mantente al corriente hasta que el periodo de pre-venta abra en breve":"Please stay tuned until pre-sales period is open soon!"}</p>           
                </Banner>
            </div>
        )
    }
}

export default MetaMaskConn;
