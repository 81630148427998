import React, { useState, useRef, useEffect, useContext } from 'react';
import { useFilteredFaqs } from '../utils/FilteredFaqs'
import { UserContext } from '../context/UserContext'

import Transition from '../utils/Transition.js';

import { show_paragraph } from '../utils/Markdown'
import { Link } from 'react-router-dom';
import './Faqs.css'


function Faqs() {
  const { browserLang } = useContext(UserContext);  
  const filteredFaqs = useFilteredFaqs(browserLang);
  const filteredFaqs_en = useFilteredFaqs('en');
  const filteredFaqs_es = useFilteredFaqs('es');

  const [active, setActive] = useState(0);
  const [autorotate, setAutorotate] = useState(true);
  const [autorotateTiming] = useState(7000);
  const [items,setItems] = useState(filteredFaqs[0])

  useEffect(() => {    
    setItems(browserLang==='es'?filteredFaqs_es[0]:filteredFaqs_en[0])
  }, [browserLang]);
  
  const testimonials = useRef(null);
  
  const stopAutorotate = () => {
    setAutorotate(null);
  }
  
  const heightFix = () => {
    if (testimonials.current.children[active]) {
      testimonials.current.style.height = testimonials.current.children[active].offsetHeight + 'px'
    }
  }
  
  useEffect(() => {
    if (!autorotate) return
    const interval = setInterval(() => {
      setActive(active + 1 === items.length ? 0 : active => active + 1)
    }, autorotateTiming)
    return () => clearInterval(interval)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, autorotate])
  
  useEffect(() => {
    heightFix()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  
  return (
    
    <section>
      <div className="mx-auto px-4 sm:px-6 md:py-5 border-t border-gray-800 faq-section">
        <div className="py-12 border-t border-gray-800 lg:border-0 lg:pt-0">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h2 className="h2">Frequently Asked Questions</h2>
            <h3 className="faq-summary">{browserLang==='es'?"Ves a":"Go to"} <Link to="/help">{browserLang==='es'?"AYUDA":"HELP"}</Link> {browserLang==='es'?"para":"to"} <strong>{browserLang==='es'?"acceder a todas ellas, o visitanos en":"access all of them, or visit us at"}</strong></h3>
            <h3 className="faq-summary">
                <a href="https://medium.com/@rpsnft_art" 
                  target="_blank" rel="noreferrer" 
                  className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Medium">
                  Medium
                </a>
            </h3>
          </div>

          {/* Carousel area*/}
          <div className="max-w-2xl mx-auto">

            {/* Carousel */}
            <div className="mt-6">

              {/* Testimonials */}
              <div className="relative flex items-start" ref={testimonials}>

                {items.map((item, index) => (
                  <Transition
                    key={index}
                    show={active === index}
                    appear={true}
                    className="text-center"
                    enter="transition ease-in-out duration-500 transform order-first"
                    enterStart="opacity-0 scale-98"
                    enterEnd="opacity-100 scale-100"
                    leave="transition ease-out duration-300 transform absolute"
                    leaveStart="opacity-100 scale-100"
                    leaveEnd="opacity-0 scale-98"
                  >                    
                    <blockquote className="question-faq">{item.question}</blockquote>
                    <div className="text-gray-700 font-medium mt-3">
                      { show_paragraph(item.answer,item.answerClassName) }
                    </div>
                  </Transition>
                ))}

              </div>

              {/* Bullets */}
              <div className="flex justify-center mt-6">

                {items.map((item, index) => (
                  <button className="p-1 group" key={index} onClick={() => { setActive(index); stopAutorotate(); }}>
                    <span className={`block w-2 h-2 rounded-full group-hover:bg-gray-400 transition duration-150 ease-in-out ${active === index ? 'bg-gray-200' : 'bg-gray-500'}`}></span>
                  </button>
                ))}

              </div>

            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
