import { faqs as faqsEn } from '../data/en/faq'
import { faqs as faqsEs } from '../data/es/faq'

export const filter_faq = (data,group) => { 
  
    return (
      data.filter((data,key) => {     
        return(data.group.localeCompare(group)?false:true);
      })  
    )
  }

export function useFilteredFaqs(lang) {
  
    let faqs = faqsEn;
  
    switch(lang) {
      case "es":
        faqs = faqsEs;  
        break;    
    }
  
    const filteredFaqs =  [
      filter_faq(faqs,'wow'), // FAQs - Ways of Working
      filter_faq(faqs,'price'), // FAQs - Price
      filter_faq(faqs,'roadmap'), // FAQs - Roadmap
      filter_faq(faqs,'benefits'), // FAQs - Benefits
      filter_faq(faqs,'security') // FAQs - Security
    ];
   
    return filteredFaqs;
  }