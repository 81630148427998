import React from 'react';

import Header from '../partials/Header';
import FeaturesZigzag from '../partials/FeaturesZigzag';
import PageIllustration from '../partials/PageIllustration';
import Timeline from '../partials/Timeline';
import Faqs from '../partials/Faqs';
import HeroHome from '../partials/HeroHome';
import Process from '../partials/Process';
import Footer from '../partials/Footer';
import Team from '../partials/Team';
import GettingToKnow from '../partials/GettingToKnow';
import { Helmet } from 'react-helmet';
import FollowUs from '../partials/FollowUs';
import MarketPlaces from '../partials/Marketplaces';
import ContractAddr from '../partials/ContractAddr';

function Home() {
  return (
    <>
    <Helmet>
      <title>RPS NFT Art - Home</title>  
      <meta name="description" content="Rock Scissors Paper NFT for RPS Oracle Distributed Service. Bring unbiased decision power to the web3 community."/>      
    </Helmet>

    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <HeroHome />        
        <GettingToKnow />
        <ContractAddr />        
        <MarketPlaces/>
        <Process />
        <FeaturesZigzag />
        <Timeline />        
        <Faqs />                
        <Team />        
        <FollowUs />
      </main>

      {/*  Site footer */}
      <Footer />

    </div>
    </>
  );
}

export default Home;