import {useContext,useState} from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { UserContext } from "../../context/UserContext"
import ReactGA from 'react-ga'

// Needed for Smart Contracts
import {getWeb3} from "./getWeb3"

const MintingForm= ({
    top_per_tx,
    price,
    refresh_supply_cb,
    ...props
}) => {
    
    const {browserLang, lastId,accounts,maxDrop, scissors, 
            canIMintPresales, setCanIMintPresales,
            inPublicSales, setInPublicSales,
            inPreSales, setInPreSales,
            presalesFinished, setPresalesFinished,
            publicsalesFinished, setPublicsalesFinished} = useContext(UserContext)
    const [cost, setCost] = useState(price)
    const [numTokens, setNumTokens] = useState(1)
    const max_per_tx = inPreSales?2:top_per_tx;

    // Main minting function
    const mintScissors = async (e) => {

        ReactGA.event({
            category: 'GOAL',
            action: 'MINT',
            label: numTokens
        })
        
        // Get network provider and web3 instance.
        const web3 = await getWeb3()

        e.preventDefault()

        if (isNaN(accounts)) {
            return
        }

        // Calculate cost of transaction
        setCost(numTokens * price);
        /*console.log("*********************************");
        console.log("NumTokens = "+numTokens)
        console.log(numTokens*price)
        console.log("cost = "+cost)*/
        const _cost = numTokens*price;

        //console.log("inPreSales? "+inPreSales);
        //console.log("inPublicSales? "+inPublicSales);
        
        if(inPreSales) {
            // Do the minting and refresh value 
            if(canIMintPresales) {

                await scissors.methods.preSalesMint(numTokens).send({from: accounts[0], 
                    value: web3.utils.toWei(''+_cost,'ether')})
                    .on('receipt', async () => {
                        await  refresh_supply_cb() // Call provided refreshSupply callback
                    });
                
            } 
        } else if (inPublicSales) {
                await scissors.methods.mint(numTokens).send({from: accounts[0], 
                    value: web3.utils.toWei(''+_cost,'ether')})
                    .on('receipt', async () => {
                        await  refresh_supply_cb() // Call provided refreshSupply callback
                    });
        } 
    }

    /* Get the maximum number of NFTs for this drop */
    const iMaxDrop = parseInt(maxDrop)

    /* boolean that controls in the client side if user can mint or not. 
       if this control is changed this will lead to gas consumption as there are 
       revert checks in the smart contract that prevent with same rules in the DApp */
    const canMint = (numTokens > 0) && 
                    (numTokens <= max_per_tx) && 
                    (lastId < iMaxDrop) && inPublicSales

    const isAccountsUnlocked = accounts ? accounts.length > 0 : false 
        
    /** Controls input to allow just numbers in the range 1-25 */
    const inputChanged= async (e) => {
        let value = e.target.value 

        if(isNaN(value)) {
            e.preventDefault()
            return;
        }

        if(value==='') {
            value=1;
            setNumTokens(1);
            return;
        } 

        let iValue = parseInt(value);

        if(iValue <= 0)
            iValue = 1;

        if(iValue > max_per_tx)
            iValue = max_per_tx;

        setNumTokens(iValue)
    }

    const publicMintButton = (
        <>
            <Button variant="primary" size="lg" 
                className="btn text-white bg-purple-600 hover:bg-purple-700 w-full" 
                onClick={(e) => mintScissors(e)} 
                disabled={!isAccountsUnlocked || !canMint}>
                    {browserLang==='es'?"Mint en Venta Pública!":"Public Sales Mint!"}
            </Button>
        </>
    )

    const preSalesMintButton = (
        <>
            <Button variant="primary" size="lg" 
                className="btn text-white bg-purple-600 hover:bg-purple-700 w-full" 
                onClick={(e) => mintScissors(e)} 
                disabled={!isAccountsUnlocked || !canIMintPresales}>
                    {browserLang==='es'?"Mint en Pre-Venta":"Pre-Sales Mint!"}
            </Button>
        </>
    )

    // the Form
    const mintingForm = (
        <>
        <div className="w-50 mx-auto">
            <Form onSubmit={e => { e.preventDefault(); }}>
                <Form.Group>
                    <Form.Label>{browserLang==='es'?"Número de Tijeras a crear":"Number of Scissors to mint"}</Form.Label>
                    <Form.Control type="text" 
                        value={numTokens} 
                        onChange={(e) => {inputChanged(e)}} />
                    <Form.Label>{browserLang==='es'?"Precio de Transacción":"Transaction Price"} : {numTokens*price} ETH (@ {price} {browserLang==='es'?"cada uno":"each"})</Form.Label>
                </Form.Group>   
                <br></br>  

                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    {!presalesFinished?preSalesMintButton:publicMintButton}
                  </div>
                </div>
            </Form>
        </div>
        </>
    )
    
    if(lastId < iMaxDrop) {
        return (
            <div className="text-white">
                {mintingForm}
            </div>
        )
    } else {
        return (
            <div className="text-white text-center">
                <h1 className="h1 text-indigo-600"><strong>{browserLang==='es'?"AGOTADO!":"SOLD OUT!"}</strong></h1>
                <h3 className="h3 text-indigo-300">{browserLang==='es'?"Nuevos drops están al caer. Mantente al corriente!":"New drops comming soon. Stay tuned!"}</h3>
            </div>
        )
    }
}

export default MintingForm;