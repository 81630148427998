import React from 'react';
import ReactGA from 'react-ga'

function MarketPlaces() {
  const evtMarketVisit = _label => {
    ReactGA.event({
      category: 'MARKETPLACES',
      action: 'VISIT',
      label: _label
    })
  }

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-4 md:pb-20">
            <h2 className="h2" data-aos="fade-up">Marketplaces</h2>
          </div>

          {/* MarketPlaces */}
          <div className="max-w-sm mx-auto md:max-w-none">
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">

              {/* OpenSea */}
              <article className="flex flex-col h-full" data-aos="fade-up">
                <header>
                  <a className="block mb-6" href="https://opensea.io/collection/rps-scissors"  
                      target="_blank" rel="noreferrer" 
                      onClick = {() => evtMarketVisit('OPENSEA')}>
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-y-10 w-fit h-fit scale-90 object-cover bg-white transform hover:scale-110 transition duration-700 ease-out" 
                           src={require('../images/OpenSea_Logo.png').default} 
                           width="352" height="198" alt="OpenSea" />
                    </figure>
                  </a>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-purple-600 hover:bg-white transition duration-150 ease-in-out"
                           href="https://opensea.io/collection/rps-scissors"  
                           target="_blank" rel="noreferrer" 
                           onClick = {() => evtMarketVisit('OPENSEA')}>OpenSea</a>
                      </li>                      
                    </ul>
                  </div>                  
                </header>                                
              </article>

              {/* Rarible */}
              <article className="flex flex-col h-full" data-aos="fade-up" data-aos-delay="200">
                <header>
                  <a className="block mb-6" href="https://rarible.com/rpsnft_art"                       
                      target="_blank" rel="noreferrer" 
                      onClick = {() => evtMarketVisit('RARIBLE')}>
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img className="absolute inset-0 w-full h-fit scale-90 object-cover bg-white transform hover:scale-110 transition duration-700 ease-out" 
                           src={require('../images/rarible.png').default} 
                           width="352" height="198" alt="Rarible" />
                    </figure>
                  </a>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <a className="inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-pink-500 hover:bg-white transition duration-150 ease-in-out" 
                          href="https://rarible.com/rpsnft_art"                           
                          target="_blank" rel="noreferrer" 
                          onClick = {() => evtMarketVisit('RARIBLE')}>Rarible</a>
                      </li>                      
                    </ul>
                  </div>
                </header>
              </article>
            </div>
          </div>          
        </div>
      </div>
    </section>
  );
}

export default MarketPlaces;
