import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext'
import "./Team.css"

function Team() {

  const { 
    browserLang,
    } = useContext(UserContext);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">{browserLang==='es'?"Nuestro Apasionado Equipo Dev":"Our Passionate Dev Team"}</h2>
            <p className="text-xl text-gray-400">
              {browserLang==='es'?"El equipo con representación de género equilibrada está compuesto por 4 Co-fundadores, Senior Devs y Artistas 3D Superiores":"The Gender Balanced CoreTeam is composed of 4 Co-founders with Senior Devs and Top Edge 3D Artists"}
              </p>
              <a href="https://discord.gg/7gYMQ2spVa" 
                  target="_blank" rel="noreferrer" 
                  className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Medium">
                  {browserLang==='es'?"Contacta con el Dev Team":"Contact the Dev Team!"}
              </a>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>

            {/* 1st member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/ss_avatar.png').default} width="120" height="120" alt="Scissors Sister" />
                <h4 className="text-center text-xl font-medium mb-1">Scissors Sister</h4>
                <div className="text-center text-gray-500 mb-1">Product Owner - Marketing & Creative 3D Co-Director</div>
                <a href="https://discordapp.com/users/887416381467918346/" target="_blank" rel="noreferrer" className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">@scissors_sister</a>
              </div>
            </div>

            {/* 2nd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/rl_avatar.png').default} width="120" height="120" alt="Team member 02" />
                <h4 className="text-center text-xl font-medium mb-1">Rose Lizard</h4>
                <div className="text-center text-gray-500 mb-1">3D Artist & Creative co-director</div>
                <a href="https://discordapp.com/users/942725980492029992/" target="_blank" rel="noreferrer" className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">@rose_lizard</a>
              </div>
            </div>

            {/* 3rd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/rb_avatar.png').default} width="120" height="120" alt="Team member 03" />
                <h4 className="text-center text-xl font-medium mb-1">Rockbiosa</h4>
                <div className="text-center text-gray-500 mb-1">Technical co-director, 3D graph designer - Web Developer and generative algorithm</div>
                <a href="https://discordapp.com/users/895688028088066118/" target="_blank" rel="noreferrer" className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">@rockbiosa</a>
              </div>
            </div>

            {/* 4th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={require('../images/pj_avatar.png').default} width="120" height="120" alt="Team member 04" />
                <h4 className="text-center text-xl font-medium mb-1">Paper Jam</h4>
                <div className="text-center text-gray-500 mb-1">Smart Contract and Web3 developer - Head of Development - Head of Innovation</div>
                <a href="https://discordapp.com/users/675766572064964649/" target="_blank" rel="noreferrer" className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">@paperjam</a>
              </div>
            </div>
          </div>

        </div>

        <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>
          <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
            <div className="flex flex-col items-center">
              <div className="text-indigo-400 text-center text-xl">SHA-256 identity claim</div>
              <img className="mb-4" src={require('../images/qr-code_scissor_sister.png').default} data-aos="fade-up" data-aos-anchor="[data-aos-id-team]" alt="b84c78e723c1a98be61f655201c97b692e246cfef37ad4e95a124503432ccb62"></img>
            </div>
          </div>              
          <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
            <div className="flex flex-col items-center">
              <div className="text-indigo-400 text-center text-xl">SHA-256 identity claim</div>
              <img className="mb-4" src={require('../images/qr-code_rose_lizard.png').default} data-aos="fade-up" data-aos-anchor="[data-aos-id-team]" alt="4955dfdc1ab75fbd003af0834b1b8c60824e2fc5a966f0cad2d7897c1432cfac"></img>
            </div>
          </div>              
            
          <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
            <div className="flex flex-col items-center">            
              <div className="text-indigo-400 text-center text-xl">SHA-256 identity claim</div>
              <img className="mb-4" src={require('../images/qr-code_rockbiosa.png').default} data-aos="fade-up" data-aos-anchor="[data-aos-id-team]" alt="fcbcc489abc67253e085b54930fc509cf260cab59a108b254509d1489fc39365"></img>
              </div>
          </div>              

          <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
            <div className="flex flex-col items-center">            
              <div className="text-indigo-400 text-center text-xl">SHA-256 identity claim</div>
              <img className="mb-4" src={require('../images/qr-code_paper_jam.png').default} data-aos="fade-up" data-aos-anchor="[data-aos-id-team]" alt="d735dfa8fa552fcb02c42894edebb4652d44697d1636b6125d2367caf2ade334"></img>
              </div>
          </div>              

        </div>
      </div>      
      <div className="nftcal sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3">
        <p>As seen on...</p>
        <a href="https://nftcalendar.io/event/rock-paper-scissor-nft-web3-oracle-for-decision-making/" 
            target="_blank" rel="noreferrer"
            className="flex justify-center items-center text-purple-600 
                     bg-gray-800 hover:text-gray-100 hover:bg-purple-600 
                     transition duration-150 ease-in-out" aria-label="nftcal">                        
            <img className="mx-auto rounded-full" src={require('../images/nft-transparent.png').default} width="50" height="50" alt="nftcalendar" />            
        </a>
        <a href="https://nftevening.com/event/rock-paper-scissor-web3-oracle-for-decision-making/" 
            target="_blank" rel="noreferrer"
            className="flex justify-center items-center text-purple-600 
                     bg-gray-800 hover:text-gray-100 hover:bg-purple-600 
                     transition duration-150 ease-in-out" aria-label="nftcal">                        
            <img className="mx-auto rounded-full" src={require('../images/nft-logo-banner.png').default} width="150" height="40" alt="nftevening" />            
        </a>
      </div>
    </section>
  );
}

export default Team;