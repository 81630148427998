import { useState, useContext,useEffect } from "react"
import WLMintingForm from "../components/elements/WLMintingForm"
import Banner from '../components/elements/Banner'
import classNames from 'classnames';
import "./Minting.css"
import { UserContext } from "../context/UserContext";
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import PageIllustration from '../partials/PageIllustration';
import { Helmet } from "react-helmet";

const WhiteListMinting = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {


    const { 
        browserLang,
        } = useContext(UserContext);

    const getScissorsValue = () => {
        let _scissorsValue=(browserLang==='es')?"Bienvenido!":"Welcome!";
        
        if(isPaused)
            _scissorsValue = (browserLang==='es')?"Contrato Pausado":"Contract Paused";
        if(!canIMintWhiteList)
            _scissorsValue = (browserLang==='es')?"La billetera no está en la WhiteList!":"Account not in WhiteList!";
        
        return _scissorsValue;
    }

    const getWhiteListStr = () => {        
        
        let wltxt=""
        
        if(!canIMintWhiteList)
            wltxt = "We are sorry, you are not in the whitelist (anymore)... :/"            
        else {
            wltxt = "Welcome, you are in the whitelist! "
        
            if(isPaused)        
                wltxt = wltxt + " ... But cannot mint yet.";
            else
                wltxt = wltxt + " ... And you can MINT NOW!";
        }

        if(browserLang==='es') {
             
            if(!canIMintWhiteList)
                wltxt = "Lo sentimos, (ya) no estás en la whitelist... :/"            
            else {
                wltxt = "Bienvenido, está en la whitelist!"
        
                if(isPaused)        
                    wltxt = wltxt + " pero no puedes hacer mint todavía.";
                else
                    wltxt = wltxt + " y ya puedes HACER EL MINT!";
            }
        }

        return wltxt;        
    }
        
        
    const WHITELIST_PRICE = 0    
    const TOP_PER_TX = 2    

    const {accounts, scissors, maxDrop, lastId, setLastId,canIMintWhiteList,isPaused} = useContext(UserContext)

    /* Callback function that gets the current supply */
    const refreshSupply = async () => {
     
        if(!scissors) {
            return;          
        }
    
        if (isNaN(accounts)) {
            return
        }        
        
        const _lastId = await scissors.methods.totalSupply().call()
        
        setLastId(_lastId);        
    }
    
    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    
    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
      );


    const refreshLastId = async () => {
        await refreshSupply()
    }
     
    const noAccBanner = (    
        <Banner variant="danger" heading="Account not connected" connect="true">
            <p><strong>
                
            {browserLang==='es'?"Conecta con Metamask y refresca la página para poder hacer Mint.":"Connect with Metamask and refresh the page to be able to Mint."}
                
                </strong>
            </p>            
        </Banner>             
    )
        
    const isAccountsUnlocked = accounts ? accounts.length > 0 : false 
    
    const connectAccountBanner = (
        <>
        {
            !isAccountsUnlocked?
                noAccBanner
                : null
        }
        </>
    )

    const noContractBanner = (
        <Banner variant="danger" heading="Contract not found" parent={this}>
            <p>
              {browserLang==='es'?"No se pudo encontrar un contrato inteligente Scissors. Comprueba la consola para más detalles":"Could not find deployed Scissors contract. Check console for details."}
            </p>            
        </Banner>            
    )

    const ContractBanner = (
        <>
        {
            (!scissors)?
                noContractBanner
                : null
        }
        </>
    )

    const lastIdElem = (
        <>
        <div className="info-minting-text" id='lastId'>{browserLang==='es'?"Tijeras Minted":"Scissors Minted"}: {lastId} / {maxDrop}</div>
        <div className="info-json-text" id='lastIpfsJson'>{getScissorsValue()}</div>
        <p></p>
        </>
    )

    const scissorsContractInfo = (
        <>
            {lastIdElem}
        </>
    )
        
    if(!scissors || !accounts) {
        return (
        
        <section className={outerClasses}>
            <div className={innerClasses}>
                <div>
                    {connectAccountBanner}
                </div>
                <div>
                    {ContractBanner}
                </div>
            </div>
        </section>)
    }

    // Request the refresh of the current supply
    refreshSupply()
    setInterval(refreshLastId, 1000);
                
    // Show in component
    return (
        <>
            <Helmet>
                <title>RPS NFT Art - Minting</title>  
                <meta name="description" content="Minting of Scissors NFT - Rock Scissors Paper - NFT for RPS Oracle Distributed Service."/>      
            </Helmet>
    
            <div className="flex flex-col min-h-screen overflow-hidden">
                {/*  Site header */}
                <Header back="true"/>
                
                {/*  Page content */}
                <main className="flex-grow">

                    {/*  Page illustration */}
                    <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
                    <PageIllustration />
                    </div>

                    <section className="relative">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                            {/* Page header */}
                            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                                <h1 className="h1 mb-4" data-aos="fade-up">{browserLang==='es'?"Mint de tus Tijeras":"Mint your Scissors"}</h1>
                                <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">{getWhiteListStr()}</p>
                                <p className="text-xl text-red-400" data-aos="fade-up" data-aos-delay="800">{browserLang==='es'?"Billetera conectada:":"Account connected:"} {(accounts)?accounts[0]:null}</p>            
                            </div>

                            <div>
                                {connectAccountBanner}
                            </div>

                            <WLMintingForm                                
                                price={WHITELIST_PRICE} 
                                top_per_tx={TOP_PER_TX}
                                refresh_supply_cb={refreshSupply}                             
                            />

                            <br/>

                            {scissorsContractInfo}
                        </div>
                    </div>
                    </section>
                </main>
                
                {/*  Site footer */}
                <Footer />

            </div>
        </>
    );
}

export default WhiteListMinting
