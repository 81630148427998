import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext'
import { Link } from 'react-router-dom';
import Social from './Social'

const toggleLang= (_browserLang,_setBrowserLang) => {
  
  _browserLang==='es'?_setBrowserLang('en'):_setBrowserLang('es')
} 

function Footer() {

const { 
    browserLang,
    setBrowserLang
    } = useContext(UserContext);

  return (
    <footer>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">

            <Social></Social>
            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4">&copy; 2022 RPSNFT_Art. All rights reserved.</div>
            {
            <Link to="#0" onClick={() => { toggleLang(browserLang, setBrowserLang) }} >{browserLang==='es'?'🇺🇸 English, please':'🇪🇸 Español, por favor'}</Link>
             }
          </div>

        </div>
      </div>
    </footer>
  );
}

export default Footer;
