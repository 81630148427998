export const concepts = [
    {
       id: 1,
       title: "RPS Web3 Distributed Oracle",       
       body:[
       "Miles de decisiones se toman a diario tanto por personas como por aplicaciones y, **en el metaverso, será exactamente igual.** \
       El futuro está en el web3 y en el metaverso pero hoy en día los contratos inteligentes no pueden obtener los datos directamente \
       del mundo real y necesitan de los servicios de un oráculo para funcionar.",
       "**La misión de RPS es la de aportar poder de decisión no sesgado a la comunidad web3**, especificamente \
       proporcionando un servicio de oráculo web3 a la sociedad. \
       Queremos hacer esto de la forma más fácil posible usando cómo ejemplo el juego Piedra, Papel o Tijera (RPS) \
       👊✋✌️.",
       "Gracias a los servicios distribuidos del oráculo RPS 🔮 las DApps, cualquier comunidad DAO, juegos de deportes online \
       o de apuestas podrán resolver disputas de forma no sesgada y de forma distribuida en la cadena de bloques. \
       **Cualquier decisión que se tome gracias a RPS se guardará en la cadena de bloques** (Blockchain de tipo PoS) \
       de forma que se pueda consultar y evaluar los resultados a modo de evidencia y prueba en un futuro.",
       "RPS es una multi-colección de 3 drops que contienen **10k NFTS de utilidad** creados en Ethereum [ERC-721 contract](https://youtu.be/HTm-1JtI0fA).\
       Los miembros de la comunidad RPS no sólo podrán participar en concursos, usar sus coleccionables para ayudar a registrar decisiones \
       en la cadena de bloques sino que también se **beneficiarán de los beneficios del pago por uso siempre que \
       los servicios de oráculo distribuido RPS sean utilizados por otras comunidades o DApps en el metaverso.**"],
       group: "main"
    },
    {
        id: 3,        
        title:"Nuestra Misión", 
        icon:"user-astronaut",
        bodyClassName: "mission",       
        body: ["“Facilitar la toma de decisiones insesgadas en el ecosistema Web3”"],
        group: "mission"
    },
    {
        id: 4,        
        title:"Nuestra Visión",
        icon:"glasses",
        bodyClassName: "vision",
        body: ["Ser el principal servicio de oráculo para la toma de deciciones de forma descentralizada en cualquier metaverso."],
        group: "mission"
    },
    {
        id: 5,
        subtitle: "Drop 1",
        title: "10,000 Tijeras",        
        body: ["Las manos pueden ser símbolos de paz, amor y guerra y son una señal de identidad y de **poder de decisión**.",
        "**El Drop 1 es una colección de 10K tijeras únicas ✂️ hechas a partir de manos estéticas y genderfluid.**",
        "Cada tijera es una mano 3D con todo lujo de detalle que pertenece a una celebridad misteriosa y que es el resultado \
        de la unión de **más de 200 rasgos** con referencias al estilo urbano y al retro-futurismo \
        para hacer que cada tijera sea una única pieza de arte digital dentro de la colección.",        
        "Todas las tijeras cuentan pero unas son más raras que otras. Encuentra tu lugar en el metaverso y se parte del \
        poder de decisión del oráculo distribuido en el Web3."
        ],
        image: "scissors-01.png",
        group: "additional"
    },
    {
        id: 8,
        title: "Para los miembros de la comunidad",
        bodyClassName: "features",
        body: ["✔️ Plazas para **propietarios VIP**",
        "✔️ **59 ETH en premios** en el primer drop",
        "🔥 **Búsqueda del tesoro dentro de la cadena de bloques**",
        "✔️ Poder de **influenciar diseños en las futuras colecciones**",
        "✔️ **Personalización** en futuros drops",
        "✔️ **5% del beneficio de cada drop en donaciones a la caridad**",
        "👍 Ser parte en la creación del primer **servicio de oráculo distribuido RPS en la web3**",
        "✔️ **Recompensas NFT Staking**: Los propietarios de los NFT tendrán beneficios mediante **un ingreso pasivo** como resultado de los\
        servicios descentralizados del oráculo RPS a las DApps en el web3.",
        "· **50% del beneficio de los servicios del oráculo** se asignarán por el contrato inteligente \
        **a los propietarios de los NFT de forma proporcional a su entrada en el proyecto**: 25% a los propietarios de las tijeras, 15% a los papeles, 10% a los propietarios de las piedras)",
        "· **25% del beneficio de los servicios del oráculo** se asignarán por el contrato inteligente **a los jugadores RSP** que ayudarán a la toma de decisiones en la cadena de bloques",
        "· **El resto del beneficio, el 25%** se asignará por el contrato inteligente a los co-fundadores de RPS"],
        group: "differentiators"
    },
    {
        id: 9,
        title: "Diferencias Tecnológicas Innovadoras",
        bodyClassName: "features",
        body: ["✔️ **NFT ERC 721 Real** (las imagenes no están en servidores privados centralizados)",
        "👛 **Creación de un Token ERC-20** (token fungible para el pago del servicio futuro)",
        "✔️ Poder de decisión basado en un **Servicio Oráculo Distribuido Social**",
        "✔️ **Personalización** (a partir del segundo drop)",
        "🔐 **Contrato Inteligente Extremadamente Seguro** (Auditoría de [Consensys MythX](https://drive.google.com/file/d/1GG4pvLNb0YYSarhmDlDKC0O7IWvhu_Ll/view?usp=sharing))",
        "⚖️ [Uso de modelos matemáticos RPS](https://medium.com/@rpsnft_art/rps-nft-art-as-a-decentralized-social-oracle-service-749aa8056fe5) para \
        la toma de decisiones insesgadas y justas (**teoría de juegos evolutiva y de suma cero**) \
        , en favor del puro azar de otros servicios de oráculo que simplemente usan lanzamiento de moneda"],
        group: "differentiators"
    },
    {
        id: 10,
        title: "Para la Industria",
        bodyClassName: "features",
        body: ["✔️ **Colaboraciones** con Marcas y Artistas",
        "🔮 **Servicio de Oráculo Distribuido WEB3 RPS** para el beneficio de otras DApps (DAOs, marketplaces, juegos, metaversos, etc...)"],
        group: "differentiators"
    }
]