import {Container, Row, Col, Alert} from 'react-bootstrap'
import "./Banner.css"

const Banner = ({
    variant,
    heading,
    heading_class,
    children,
    ...props
}) => {

    return (
        <>
            <Container className="banner-text">
                <Row>
                    <Col className="col-md-3"/>
                    <Col className="col-md-6">
                        <Alert variant={variant}>
                            <Alert.Heading className={heading_class}>{heading}</Alert.Heading>
                            {children}
                        </Alert>                
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Banner;