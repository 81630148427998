import { show_paragraph } from './Markdown'

export const summaryRoadmap = (title, roadmapItems) => {
    const theImg = (data,key) => (
      <div className={`max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:${(key % 2)==0?'order-1':'rtl'}`} data-aos="fade-up">
          <img className="max-w-full mx-auto md:max-w-none h-auto rounded-lg" src={require(`../images/${data.image}`).default} width="500" height="500" alt={`Scissors ${key}`} />
      </div>
    );
  
    return (
      <>
        <h1 className="h2 mb-4 text-center">{title}</h1>
        {
          roadmapItems.map((data,key) => {
            
            return(
              <>        
              <div className="font-architects-daughter text-xl text-purple-600 mb-2">{data.subtitle}</div>
  
              <div className="py-5 md:grid md:grid-cols-12 md:gap-6 items-center" key={key}>
                { data.image && theImg(data,key) }                    
                
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" key={key}>
                  <h2 className="h2 mb-4">{data.title}</h2>
                  
                  { show_paragraph(data.body,data.bodyClassName) }
                </div>
              </div>
              </>
            );
          })
        }
      </>
    );
  }
  
export const roadmapItems = (title, roadmapItems) => {
    return (
      <div className="max-w-3xl mx-auto -my-4 md:-my-6 py-10 border-t border-gray-800" data-aos-id-timeline>
          <h1 className="h2 mb-4 text-left">{ title }</h1>
          {
              roadmapItems.map((data,key) => {
              return(                  
                <div className="relative py-4 pl-14" data-aos="fade-up" data-aos-anchor="[data-aos-id-timeline]" key={key}>
                  <div className="pl-2">                    
                    <div className="flex items-center mb-3 mx-20">
                      <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-10 text-green-600 bg-green-200 rounded-full">{data.date}</div>
                      <div className="absolute left-0 h-full px-px bg-gray-800 ml-6 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                      <div className="absolute left-0 w-2 h-2 bg-purple-600 ml-6 border-4 box-content border-gray-900 rounded-full transform -translate-x-1/2" aria-hidden="true"></div>                                        
                    </div>
                    
                    { show_paragraph(data.body,data.bodyClassName) }
                  </div>
                </div>
              );
            })
          }
      </div>
    );
  }
  