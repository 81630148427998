import {getWeb3} from "./getWeb3"
import map from "./../../artifacts/deployments/map.json"
    
// Connect to metamask 
  export const connectMetamask = async() => {
        // Get network provider and web3 instance.
        const web3 = await getWeb3()

        // ethereum.enabled is deprecated, and it is what default boilerplate 
        // from brownie uses
        // see https://forum.ivanontech.com/t/dapp-introduction/10054/342 
        // using not-deprecated method requestAccounts() instead
        if (typeof window.ethereum !== "undefined" && web3) {

            let accounts;

            try {
                accounts=await web3.eth.requestAccounts();
            } catch(error) {
                const message = error.message || "";
                console.log(message);
            } 

            return accounts;
        } else {
            alert("Please install Metamask")

            // eslint-disable-next-line
            throw('Cannot connect to Metamask');
        }
    }
    
export const loadContract = async (web3,chain, contractName) => {
        // Load a deployed contract instance into a web3 contract object
    
        // Get the address of the most recent deployment from the deployment map
        let address
        try {
            address = map[chain][contractName][0]
        } catch (e) {
            console.log(`Couldn't find any deployed contract "${contractName}" on the chain "${chain}".`)
            return undefined
        }
    
        // Load the artifact with the specified address
        let contractArtifact
        try {
            contractArtifact = await import(`./../../artifacts/deployments/${chain}/${address}.json`)
        } catch (e) {
            console.log(`Failed to load contract artifact "./../../artifacts/deployments/${chain}/${address}.json"`)
            return undefined
        }
        
        return new web3.eth.Contract(contractArtifact.abi, address)
    }

export const loadInitialContracts = async (web3,chainid,setScissors,setMaxDrop) => {    
        // <=42 to exclude Kovan, <42 to include kovan
        /*if (chainid < 42) {
            // Wrong Network!
            return
        }*/
        
        console.log("Connected to chainId:")
        console.log(chainid)
        
        var _chainID = 0;

        if (chainid === 42){
            _chainID = 42;
        }

        // Mainnet
        if (chainid === 1) {
            _chainID = 1;
        }
        
        // RinkeBy
        if (chainid === 4) {
            _chainID = 4;
        }

        if (chainid === 1337){
            _chainID = "dev"
        }
        
        console.log(_chainID)
    
        // Save contract in state
        const _scissors = await loadContract(web3,_chainID,"Scissors")
        setScissors(_scissors)
    
        if (!_scissors) {
            return
        }
        
        const _maxDrop = await _scissors.methods.getMaxDrop().call()
        setMaxDrop(_maxDrop)
    }

    // Method to connect the account relying on connectMetamask()
export const doConnectAccount = async (setAccounts,setScissors, setMaxDrop) => {

        // Get network provider and web3 instance.
        const web3 = await getWeb3()

        // Try and enable accounts (connect metamask)
        // Use web3 to get the user's accounts
        const _accounts = await connectMetamask()
   
        setAccounts(_accounts);

        // Get the current chain id
        const _chainId = parseInt(await web3.eth.getChainId())

        console.log("_chainId ="+_chainId)

        await loadInitialContracts(web3,_chainId,setScissors,setMaxDrop)
        
        return _accounts;
    }

export const IsContractPaused = async (accounts, scissors, setIsPaused) => {

    if (isNaN(accounts)) {
        return
    }

    // Do the minting and refresh value 
    const ret = await scissors.methods.paused().call({from: accounts[0]});
    //console.log("IsPaused? = "+ret);
    setIsPaused(ret);
    return ret;    
}
        
export const AmIinWhiteList = async (accounts, scissors, setCanIMintWhiteList) => {
    
    if (isNaN(accounts)) {
        return
    }

    // Do the minting and refresh value 
    const ret = await scissors.methods.AddrInWhiteList(accounts[0]).call({from: accounts[0]});
    //console.log("AmIinPresales? = "+ret);
    setCanIMintWhiteList(ret);
    return ret;    
}

export const AmIinPreSalesList = async (accounts, scissors, setCanIMintPresales) => {
    
        if (isNaN(accounts)) {
            return
        }

        // Do the minting and refresh value 
        const ret = await scissors.methods.AmIinPresales().call({from: accounts[0]});
        //console.log("AmIinPresales? = "+ret);
        setCanIMintPresales(ret);
        return ret;    
    }

export const AreWeInPreSales = async (accounts, scissors, setInPreSales) => {
    
        if (isNaN(accounts)) {
            return
        }
    
        // Do the minting and refresh value 
        const ret = await scissors.methods.inPresales().call();
        //console.log("AreWeInPresales? = "+ret);
        setInPreSales(ret);
        return ret;    
    }

    
export const IsPresalesFinished = async (accounts, scissors, setPresalesFinished) => {
    
    if (isNaN(accounts)) {        
        return
    }

    // Do the minting and refresh value 
    const ret = await scissors.methods.preSalesFinished().call();
    //console.log("IsPresalesFinished? = "+ret);
    setPresalesFinished(ret);
    return ret;    
}

export const AreWeInPublicSales = async (accounts, scissors, setInPublicSales) => {
    
        if (isNaN(accounts)) {                        
            return
        }
    
        // Do the minting and refresh value 
        const ret = await scissors.methods.inPublicSales().call();
        //console.log("AreWeInPublicSales? = "+ret);
        setInPublicSales(ret);
        return ret;    
    }

        
export const IsPublicSalesFinished = async (accounts, scissors, setPublicSalesFinished) => {
    
    if (isNaN(accounts)) {        
        return
    }

    // Do the minting and refresh value 
    const ret = await scissors.methods.publicSalesFinished().call();
    //console.log("IsPublicSalesFinished? = "+ret);
    setPublicSalesFinished(ret);
    return ret;    
}


