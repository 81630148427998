export const roadmaps = [
    {
        id: 1,
        title: 'Creating Community',
        body: ["We want to create a big community and we want **to reward members :gift::gift::gift: that help us to achieve \
        our goal :goal_net:**. Once we achieve the 50% of minting :thermometer:, the :three: community members who have \
        brought more users to our discord will be rewarded with:",
        "- First: **2.5 ETH**",
        "- Second: **1 ETH**",
        "- Third: **0.5 ETH**"],
        image: 'community.jpg',
        group: 'summary'
    },
    {
        id: 2,
        title: 'VIPs',
        body: ["This project is too good to be only ours, we are looking for VIP :sunglasses: community members to join us in \
        this journey. **:two::zero: special scissors NFT`s will be published at [OpenSea](https://opensea.io/collection/rps-scissors)**.",
        "**The owners of these cards will get very generous benefits** very similar to the ones of the \
        core team members."],
        image: 'vip.jpg',
        group: 'summary'
    },
    {
        id: 3,
        title: 'Pre-sales',
        body: ["We want to guarantee spots :seat::seat::seat::popcorn: for our community members.\
        During :three: days a special scissor :v: will be available for bidding at [opensea](https://opensea.io/collection/rps-scissors). After this period, \
        the best bidder will get the card and the best :two::five::zero: bidders will be whitelisted :page_with_curl: for presales.",
        "Also, we want to encourage creativity and a sense of community, so the :two::five: most active \
        :runner: and original :clown_face: community members will be rewarded with a presales list place."],
        image: 'presales.jpg',
        group: 'summary'
    },
    {
        id: 8,        
        date: 'February 2022',
        body: ["Creación de comunidad. Queremos recompensar a los miembros que nos ayuden a hacer crecer nuestra comunidad. \
        **Al 50% del minting**, \
        los 3 miembros de la comunidad más activos que hayan traido más usuarios a nuestro servidor de Discord serán recompensados con estos premios:",
        "- Primer puesto: **2.5 ETH**",
        "- Segundo puesto: **1 ETH**",
        "- Tercer puesto: **0.5 ETH**"],
        group: 'dates q1'
    },
    {
        id: 9,
        date: 'March 2022',
        body: ["Tijeras VIP: **20 NFTs de tijeras especiales se publicarán** en [OpenSea](https://opensea.io/collection/rps-scissors). \
            Los miembros de la comunidad que posean los NFT VIP tendrán numerosos generosos beneficios (muy similares a los del equipo core)."],
        group: 'dates q1'
    },
    {
        id: 10,        
        date: 'March 2022',
        body: ["Plazas whitelist para Pre-ventas.","Una **tijera especial** estará disponible durante 3 días para su puja en [OpenSea](https://opensea.io/collection/rps-scissors).\
        El pujante con mayor apuesta obtendrá la pieza y **los mejores 250 apostantes** estarán automaticamente en la lista whitelist para pre-ventas. \
        También queremos promover la creatividad y el sentido de comunidad, de forma que los más activos y originales miembros de la comunidad \
        tendrán también puestos en la lista de pre-sales (1500 puestos adicionales)."],
        group: 'dates q1'
    },
    {
        id: 12,        
        date: '18-March-2022',
        body: ["**Comienza la Pre-venta**. Los miembros de la comunidad en la whitelist Whitelisted para preventas pueden comenzar a hacer el minting \
        de sus primeras Tijeras ✌️ during una semana (un máximo de 2 por cuenta)"],
        group: 'dates q1'
    },    
    {
        id: 121,        
        date: '25-March-2022',
        body: ["✨✨ **La Venta Pública Empieza** ✨✨"],        
        group: 'dates q1'
    },
    ,    
    {
        id: 13,        
        date: '25% minted',
        body: ["Más y más tijeras aparecen en el metaverso. **Cuando se alcanza el 25% de ventas**, cinco tijeras especiales hacen su entrada \
        en el metaverso. \
        **5 NFT se regalan** y **5 ETH se acumulan en el bote del Tesoro de la cadena de bloques**"],        
        group: 'dates q1'
    },    
    {
        id: 14,        
        date: '50% minted',
        body: ["La mitad de las Tijeras han llegado al metaverso y los conflictos comienzan. **4 ETH se distribuyen a los miembros de la comunidad** \
        que hayan traido más usuarios y **se añaden 10 ETH más al bote del Tesoro de la cadena de bloques**"],        
        group: 'dates q1'
    },    
    {
        id: 15,        
        date: '75% minted',
        body: ["Extraños acontencimientos comienzan a suceder ... El Tesoro de la cadena de bloques continua creciendo. **Se distribuyen \
        5 ETH a los miembros de la comunidad** y el **bote del Tesoro añade 15 ETH más**"],        
        group: 'dates q1'
    },    
    {
        id: 16,        
        date: '100% minted',
        body: ["Las Tijeras se organizan para resolver el misterio y **20 ETH se añaden ** al total del bote del Tesoro, \
        **5% de los beneficios de las ventas se donan para contribuir con el pueblo de Ucrania** [via 🇺🇦UkraineDAO🇺🇦](https://twitter.com/Ukraine_DAO). \
        El equipo RPS está con el pueblo de Ucrania y no sólo queremos tener un contrato inteligente sino también justo. \
        En nuestro contrato inteligente incluiremos [su dirección](https://etherscan.io/address/0x633b7218644b83d57d90e7299039ebab19698e9c) a fuego",
        "**CAZA DEL TESORO de los 50 ETH COMIENZA EN LA CADENA DE BLOQUES!!**"],        
        group: 'dates q1'
    },
    {
        id: 17,
        date: 'Q2 2022',
        body: ["Los papeles Papers aterrizan en el metaverso. **10k papeles encuentra también su lugar**. Cada papel es una pieza única \
        de arte que pertenece a un caracter famoso intelectual. Los conflictos comenzarán. La influencia en el diseño y la personalización \
        será la clave para avanzar en la aventura."],        
        group: 'dates q1'
    },
    {
        id: 18,        
        date: 'Q3 2022',
        body: ['La venganza de las Piedras',"**10k piedras llegan al metaverso**. Las Piedras siempre han sido vistas como \
        los chicos malos y misterioros. Quién tiene o no razón? Dejemos que el oráculo distribuido RPS decida..."],        
        group: 'dates q1'
    },    
    {
        id: 19,        
        date: 'Q4 2022',
        body: ["El **RPS Token** ERC-20 se crea junto con el **primer Servicio de Oráculo Distribuido basado en RPS**.",
        "**Las recompensas NFT staking entre los propietarios de la colección RPS** ase activan en un nuevo contrato inteligente."],        
        group: 'dates q1'
    }
]