import React from 'react';

import Header from '../partials/Header';
import FeaturesZigzag from '../partials/FeaturesZigzag';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';
import { Helmet } from 'react-helmet';

function Scissors() {
  return (
    <>
      <Helmet>
        <title>RPS NFT Art - Scissors NFT</title>  
        <meta name="description" content="Scissors NFT - Rock Scissors Paper - NFT for RPS Oracle Distributed Service."/>      
      </Helmet>

      <div className="flex flex-col min-h-screen overflow-hidden">

        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">

          {/*  Page illustration */}
          <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
            <PageIllustration />
          </div>

          {/*  Page sections */}
          <FeaturesZigzag />
        </main>

        {/*  Site footer */}
        <Footer />

      </div>
    </>
  );
}

export default Scissors;