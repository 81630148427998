import { BrowserRouter,Switch, Route } from 'react-router-dom';

// Pages
import Home from '../pages/Home';
import Minting from '../pages/Minting';
import WhiteListMinting from '../pages/WhiteListMinting';
import Help from '../pages/Help';
import PageNotFound from '../pages/PageNotFound';
import KeyFeatures from '../pages/KeyFeatures';
import Scissors from '../pages/Scissors';
import AboutUs from '../pages/AboutUs'
import Roadmap from '../pages/Roadmap';

// Components
import MetaMaskConn from '../components/elements/MetamaskConn';

const buttonMint = () => {
    return (
    <MetaMaskConn>
        Connect!
    </MetaMaskConn>);
}

const AppRouter = () => {
    return ( 
    <BrowserRouter>
        <Switch>
            {/* main page */}
            <Route exact path="/" buttonMint={buttonMint}>
                <Home />
            </Route>
            {/* Route to minting */}
            <Route exact path="/mint">
                <Minting className="illustration-section-01" />
            </Route>
            {/* Route to whitelist minting */}
            <Route exact path="/wlmint">
                <WhiteListMinting className="illustration-section-01" />
            </Route>
            {/* What are the main features of the service that we will offer */}
            <Route path="/features">
                <KeyFeatures/>
            </Route>
            {/* Scissors in detail */}
            <Route path="/scissors">
                <Scissors />
            </Route>
            {/* The Roadmap */}
            <Route path="/roadmap">
                <Roadmap/>
            </Route>
            {/* About Us*/}
            <Route path="/team">
                <AboutUs/>
            </Route>
            {/* Help Center */}
            <Route path="/help">
                <Help />
            </Route>
            {/* 404 */}
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    </BrowserRouter>
    );
}

export default AppRouter;